@import "../../stylesheets/robotoFonts";

.depot-chargers-content {
  display: flex;
  margin-left: 160px;
  margin-top: 15px;
}
.depot-chargers:hover{
  //background-color: blue;
  //padding-bottom: 13px;
  background: url("../../assets/images/hover/Icon\ metro-power.png") #e9e9e9 no-repeat center;
  //height: 100%;
}
.depot-charger-status-text {
  text-align: left;
  //font: normal normal medium 14px/16px Roboto;
  //letter-spacing: 1.4px;
  color: #000000;
  letter-spacing: 0px;
  color: #19387c;
  opacity: 1;
  opacity: 1;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  margin-left: 20px;
  margin-top: 10px;
}
.depot-chargers-text {
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
  margin-top: -10px;
  margin-left: 120px;
}
.depot-img {
  margin-top: 20px;
  margin-left: 310px;
}
.depot-chargerss-text {
  text-align: left;
  //font: normal normal medium 14px/16px Roboto;
  //letter-spacing: 1.4px;
  letter-spacing: 0px;
  color: #353844;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  //margin-left: -74px;
  margin-top: -5px;
  //margin-top: -10px;
}

.depot-chargerss-text-2 {
  text-align: left;
  //font: normal normal medium 14px/16px Roboto;
  //letter-spacing: 1.4px;
  margin-top: -5px;
  letter-spacing: 0px;
  color: #353844;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  //margin-left: -77px;
  // margin-top: 10px;
  // margin-top: -10px;
}

.depot-chargerss-text-3 {
  text-align: left;
  //font: normal normal medium 14px/16px Roboto;
  //letter-spacing: 1.4px;
  margin-top: -5px;
  letter-spacing: 0px;
  color: #353844;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  //margin-left: -89px;
  // margin-top: 10px;
  //margin-top: -10px;
}

.depot-chargerss-text-4 {
  text-align: left;
  //font: normal normal medium 14px/16px Roboto;
  //letter-spacing: 1.4px;
  // margin-top: -5px;
  letter-spacing: 0px;
  color: #353844;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  //margin-left: -83px;
  // margin-top: 10px;
  // margin-top: -5px;
}
.depot-charger-number-1 {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #7aae4f;
  text-transform: uppercase;
  opacity: 1;
  font-weight: $font-name-Roboto-Medium;
  margin-left: 40px;
  margin-top: 10px;
}
.depot-charger-number-2 {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #f5c06b;
  text-transform: uppercase;
  opacity: 1;
  font-weight: $font-name-Roboto-Medium;
  margin-left: 45px;
  margin-top: 10px;
}
.depot-charger-number-3 {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #ec9b98;
  text-transform: uppercase;
  opacity: 1;
  font-weight: $font-name-Roboto-Medium;
  margin-left: 45px;
  margin-top: 10px;
}

.depot-charger-number-4 {
  text-align: left;
  font-size: 24px;
  letter-spacing: 0px;
  color: #4b85f7;
  text-transform: uppercase;
  opacity: 1;
  font-weight: $font-name-Roboto-Medium;
  margin-left: 22px;
  margin-top: 10px;
}

.depot-charger-btn {
  border: none;
  width: 80px;
  height: 30px;
  //margin-top: -10px;
  /* height: 0px; */
  font-size: 10px;
  margin-left: 12px;
  //margin-top: -20px;
  letter-spacing: 0px;
  color: #353844;
  text-transform: capitalize;
  opacity: 1;
  border-radius: 10px;
  font-family: $font-name-Roboto-Bold;
  background: transparent;
  .depot-charger-button-png {
    height: 6px;
    padding-left: 2px;
    padding-bottom: 1px;
  }
}

.depot-charger-btn:hover {
  background-color: #d8231d;
  color: white;
}
//   .depot-charger-charge{
//     margin-top: -10px;
//   }

.frame {
  overflow: hidden;
  transition: all 0.5s ease-out;
  height: auto;
}

.imgs {
  margin-left: 10px;
  margin-top: 10px;
}
.imgss {
  margin-left: 30px;
  margin-top: 12px;
}
// .colapse{
//   border-radius: 60%;
//   height: 25px;
//   width: 25px;
//   //padding: -18px;
//   margin-top: -10px;
//   margin-left: 6px;
// }
