@import "../../stylesheets/robotoFonts";


.kpi_contractual_header{
  margin-top: -8px;
  margin-left: 10px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Medium;
  font-size: 20px;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.kpi_contractual_text {
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -25px;
  margin-left: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.kpi_contractual_image {
  width: 15px;
  margin-top: -10px;
  margin-left: -5px;
}

.kpi_contractual_footer {
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
}

.kpi_contractual_text-1 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 50px;
  margin-left: 10px;
}

.bg-gray {
  background: #dbdee8 0% 0% no-repeat padding-box;
}

.kpi_contractual_text-breakdown {
  text-align: center;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.28px;
  color: #6e6e6e;
  text-transform: uppercase;
  opacity: 1;
  margin-left: -90px;
  margin-top: 20px;
}

.kpi_contractual_image-break {
  width: 60px;
  height: 60px;
  margin-left: 30px;
  margin-top: 10px;
}

.kpi_contractual_number-breakdown {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353639;
  opacity: 1;
  margin-left: -45px;
  margin-top: -20px;
}



.kpi_contractual_head-text {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.widthss-20 {
  width: 23%;
}

.kpi_contractual_head_img-1 {
  margin-top: -52px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.kpis_contractual_head_img-1 {
  margin-top: -60px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.kpi_contractual_head_img {
  margin-top: -5px;
  margin-right: 10px;
  margin-left: -5px;
  //width: 18px;
}

.kpi_contractual_head-text-1 {
  margin-left: 37px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.kpi_contractual_head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.width-30 {
  width: 27%;
}
.bg-lightred:hover {
  background-image: linear-gradient(to bottom, #f7ced3, white);
}
.kpi_contractual_half_donut_text {
  margin-left: 40%;
  margin-top: -40px;
  // color: #f19e38;
  font-size: 23px;
  font-family: $font-name-Roboto-Bold;
}
.kpi_contractual_half_donut_text-1 {
  margin-left: 40%;
  margin-top: -40px;
  // color: #d8231d;
  font-size: 23px;
  font-family: $font-name-Roboto-Bold;
}

.fleet-availability_half_donut_text-1 {
  text-align: left;
  letter-spacing: 0px;
  color: #767d90;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 40%;
  margin-top: -20px;
}

.fleet-availability_half_donut_text-2 {
  text-align: center;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353844;
  text-transform: uppercase;
  opacity: 1;
}

.kpi_contractual_switch-field {
  font-family: $font-name-Roboto-Regular;
  //padding: 40px;
  margin-left: 210px;
  margin-top: -7px;
}
.switch-img {
  margin-left: -10px;
}
.switch-img-1 {
  margin-right: 4px;
}
.switch-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.kpi_contractual_switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  //height: 1px;
  //width: 1px;
  border: 0;
  overflow: hidden;
}

.kpi_contractual_switch-field label {
  display: inline-block;
  width: 11vh;
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  font-size: 80%;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 2px 6px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.kpi_contractual_switch-field label:hover {
  cursor: pointer;
}

.kpi_contractual_switch-field input:checked + label {
  background-color: #18397d;
  color: #fff;
  box-shadow: none;
}

.kpi_contractual_switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.kpi_contractual_switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}

.kpi_contractual_text_realiability {
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353843;
  opacity: 1;
  margin-top: -15px;
  margin-left: 45%;
}

.kpi_contractual_text_real {
  text-align: left;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #6e6e6e;
  opacity: 1;
  margin-top: -65px;
  margin-left: 40px;
}
// .kpi_contractual_text_real {
//   margin-top: -60px;
// }
.lotty-player-kpi-contractual {
  margin-top: -15px;
 //margin-left: 1%;
  align-items: center;
}
.lotty-player-kpi-contractual-danger {
  //margin-top: -53px;
  //height: 30px;
  // width: 20px;
  // margin-top: -20px;
  //align-items: right;
  margin-bottom: 70px;
  margin-left: 39%;
 
  //margin-right: 41%;
}
.contractual_kpi_kpi_footer {
  text-align: left;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 6px;
  margin-top: -10px;
}

.relibility_kpi-btns {
  width: 26%;
  background-color: transparent;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
  font-size: 9px;
  font-weight: 700;
  font-feature-settings: normal;
  font-variant: normal;
  text-align: center;
  font-family: "Roboto-Bold";
  letter-spacing: 1px;
  color: black;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 73%;
  margin-top: -24px;
  padding: 7px 10px;

  .relibility_kpi-button-png {
    height: 6px;
    padding-left: 2px;
    padding-bottom: 1px;
  }
}

.relibility_kpi-btns:hover {
  background-color: #ed2541;
  color: white;
}

/* Parent Container */
.kpi_content_img {
  position: relative;
  width: 200px;
  margin-top: -3px;
  //height: 200px;
  float: left;
  //margin-right: 10px;
}

/* Child Text Container */
.kpi_content_img div {
  position: absolute;
  bottom: 10;
  right: 0;
  top: 3px;
  left: 15px;
  background: rgb(60, 60, 109);
  color: white;
  //margin-bottom: 5px;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
  //opacity: 0;
  backface-visibility: hidden;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.kpi_content_img:hover {
  cursor: pointer;
}

.kpi_content_img:hover div {
  width: 200px;
  padding: 8px 6px;
  visibility: visible;
  opacity: 17;
}
