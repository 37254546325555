
@import "../../stylesheets/robotoFonts";
.create_create_project {
  input[type="text"],
  select,
  input[type="date"],
  input[type="Number"],
  input[type="time"],
  input[type="duty"],
  input[type="driver"],
  input[type="list"] {
    margin-top: 10%;
    width: 100%;
    padding: 8px 10px;
    margin: 5px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    position: relative;
    background-color: #f5f5f5;
    //border-bottom: 2px solid white;
    color: black;
    font-family: $font-name-Roboto-Bold;
    height: 38px;
  }

  .xxx {
    width: 100%;
  }
  .rc-time-picker-clear {
    top: 14px;
  }
  input[type="reset"] {
    margin-top: 2%;
    width: 8%;
    height: 2rem;
    border-radius: 0.35rem;
    background-color: #0089d3;
    font-family: $font-name-Roboto-Bold;
    font-size: 1.2rem;
    letter-spacing: normal;
    color: #fff;
    border: none;
    line-height: 1;
    text-align: center;
    margin-left: 41%;
    font-size: 1.2rem;
    position: "static";
  }
  input[type="submit"] {
    margin-top: 2%;
    width: 8%;
    margin-left: 2%;
    height: 2rem;
    border-radius: 0.35rem;
    background-color: #0089d3;
    font-family: $font-name-Roboto-Bold;
    font-size: 1.2rem;
    letter-spacing: normal;
    color: #fff;
    border: none;
    line-height: 1;
    text-align: center;
    position: "static";
  }
  .download1 {
    position: relative;
    left: 75%;

    width: 8%;
    height: 2.5rem;
    border-radius: 0.5rem;
    background-color: #979797;
    font-family: $font-name-Roboto-Bold;
    font-size: 1.2rem;
    letter-spacing: normal;
    color: #fff;
    border: none;
    line-height: 1;
    text-align: center;
  }
  .upload1 {
    position: relative;
    left: 78%;

    width: 8%;
    height: 2.5rem;
    border-radius: 0.5rem;
    background-color: #979797;
    font-family: $font-name-Roboto-Bold;
    font-size: 1.2rem;
    letter-spacing: normal;
    color: #fff;
    border: none;
    line-height: 1;
    text-align: center;
  }

  .formwidth {
    width: 100%;
    height: auto;
    overflow: auto;
    font-size: 1.5rem;
    font-family: $font-name-Roboto-Bold;
  }
  .col_50 {
    width: 48%;
    float: left;
    margin-right: 1%;
    margin-left: 1%;
  }
  .col_100 {
    width: 100%;
    float: left;
    font-size: 12px;
  }

  .col_100 input{
    width: 100%;
    float: left;
    font-size: 12px;
  }
  // .col_25 {
  //   width: 50%;
  //   float: left;
  // }
  p {
    text-align: center;
    color: red;
    font-size: small;
    margin-top: 10px;
    font-family: $font-name-Roboto-Regular;
  }
  h1 {
    text-align: right;
    color: #57595d;
    font-size: small;
    margin-top: 20px;
    margin-bottom: 2%;
    font-weight: normal;
    padding-right: 15px;
    font-family: $font-name-Roboto-Bold;
  }

  .alert {
    padding: 12px 20px;
    font-size: 16px;
    position: absolute;
    color: white;
    font-family: $font-name-Roboto-Regular;
    left: 3;
    right: 0;
    top: 0;
    border-right: 10px solid #fff;
  }

  .error {
    background-color: #f44336;
  }

  .success {
    background-color: #4caf50;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
}
