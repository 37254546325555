@import "../../stylesheets/robotoFonts";


.ReactTable .rt-thead .rt-resizable-header {
  background-color: #eaedf1;
  color: #000 !important;
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}

// .driver-update-subhead {
//   display: flex;
//   //margin-left: 3%;
//   //padding-right: 20px;
//   //margin-top: -15px;
//   //margin-bottom: 10px;
// }



.create-project-update-report {
  margin-left: 30px;
  margin-bottom: 10px;
  //margin-top: 30px;
  font-family: $font-name-Roboto-Regular;
}

.create-project-subhead{
  display: flex;
}

.create-project-update-input {
  width: 400px;
  height: 30px;
  //margin-left: 15px;
  margin-top: -30px;
}



.records-info{
  margin-left: 40px;
 // margin-top: -20px;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.create-project-Report-download{
  margin-top: -25px;
  margin-left: 105%;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.create-project-Report-download-img{
 
  margin-left: 47px;
 cursor: pointer;
 margin-top: -43px;
}

.alert {
  padding: 12px 20px;
  font-size: 16px;
  position: absolute;
  color: white;
  font-family: $font-name-Roboto-Regular;
  left: 3;
  right: 0;
  top: 0;
  border-right: 10px solid #fff;
}

.error {
  background-color: #f44336;
}

.success {
  background-color: #4caf50;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}