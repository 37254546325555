@import "../../stylesheets/robotoFonts";
.terms_and_condition{
    margin-left: 30px;
    margin-right: 20px;
}

.terms_and_condition_head-main{
    font-size: 1.2em;
    //margin-bottom: 15px!important;
    line-height: 1.4em;
    color: #083a81!important;
  text-align: center;
  margin-top: 25px;
  font-family: $font-name-Roboto-Bold;
}
.terms_and_condition_head{
    font-size: 1.2em;
    //margin-bottom: 15px!important;
    line-height: 1.4em;
    color: #083a81!important;
  text-align: left;
  margin-top: 25px;
  font-family: $font-name-Roboto-Bold;
}
.terms_and_condition_head-text{
    font-size: 1.2em;
    //margin-bottom: 15px!important;
    line-height: 1.4em;
    color: #083a81!important;
  text-align: left;
  margin-top: 10px;
  font-family: $font-name-Roboto-Bold;
}

.terms_and_condition_head-text-1{
    color: #2053ae;
    font-size: 12.5px;
    font-family: $font-name-Roboto-Regular;
    margin-top: -10px;
}
.terms_and_condition_head-text-3{
    color: #2053ae;
    font-size: 12.5px;
    font-family: $font-name-Roboto-Regular;
    margin-top: 10px;
}

.terms_and_condition_head-text-2{
    line-height: 1.4em;
    color: #083a81!important;
    font-size: 16px;
    font-family: $font-name-Roboto-Bold;
}
// .terms_and_condition_head-1{
//     display: block;
//    color:#8691ac;
//     font-size: 1.5em;
//     margin-block-start: 0.83em;
//     margin-block-end: 0.83em;
//     margin-inline-start: 0px;
//     margin-inline-end: 0px;
//     font-family: $font-name-Roboto-Medium;
// }

// .terms_and_condition_subhead_1{
//     font-size: 14px;
//     color: #747d92;
//     font-family: $font-name-Roboto-Bold;
//     margin-bottom: 10px;
// }

// .terms_and_condition_subhead_2{
//     font-size: 14px;
//     color: #747d92;
//     font-family: $font-name-Roboto-Regular;
//     margin-top: 15px;
// }

// // .terms_and_condition_subhead_3{
// //     font-size: 13px;
// //     color: #747d92;
// //     font-family: $font-name-Roboto-Regular;
// // }

// .terms_and_condition_subhead_3{
//     font-size: 22px;
//     color: #747d92;
//     font-family: $font-name-Roboto-Bold;
//     margin-bottom: 10px;
// }

// .terms_and_condition_subhead_4{
//     margin-top: 15px;
//     font-size: 14px;
//     color: #747d92;
//     font-family: $font-name-Roboto-Regular;
//     margin-left: -10px;
// }

// .terms_and_condition_subhead_5{
//     font-size: 15px;
//     color: #747d92;
//     font-family: $font-name-Roboto-Bold;
   
// }