@import "../../stylesheets/robotoFonts";

.finacial_kpi_text {
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -25px;
  margin-left: 20px;
  text-align: left;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.finacial_kpi_image {
  width: 15px;
  margin-top: -10px;
  margin-left: -5px;
}

.finacial_kpi_footer {
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
}

.finacial_kpi_text-1 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 50px;
  margin-left: 10px;
}

.bg-gray {
  background: #dbdee8 0% 0% no-repeat padding-box;
}

.kpi_finacial_text-1 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #353843;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 15px;
  margin-top: 10px;
}

.kpi_finacial_text-2 {
  //text-align: right;
  margin-left: 37px;
  margin-top: 10px;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #353843;
  text-transform: capitalize;
  opacity: 1;
}

.finacial_kpi_number_tripsummary-1 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #64a63b;
  opacity: 1;
  margin-left: 50px;
  margin-top: -35px;
}

.finacial_kpi_number_tripsummary-2 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #f3be6a;
  opacity: 1;
  margin-left: 55px;
  margin-top: -35px;
}
.finacial_kpi_number_TargetTrips-1 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #64a63b;
  opacity: 1;
  margin-left: 50px;
  margin-top: -30px;
}
.finacial_kpi_number_TargetTrips-2 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #f3be6a;
  opacity: 1;
  margin-left: 55px;
  margin-top: -30px;
}

.finacial_kpi_number_2 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #f3be6a;
  opacity: 1;
  margin-top: -25px;
}
.finacial_kpi_number_1 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #64a63b;
  opacity: 1;
  margin-left: 5px;
  margin-top: -25px;
}

.finacial_kpi_number_target_revenue-1 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #64a63b;
  opacity: 1;
  margin-left: 47px;
  margin-top: -25px;
}

.finacial_kpi_number_3 {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #f3be6a;
  opacity: 1;
  margin-left: 60px;
  margin-top: -35px;
}

.finacial_kpi_image-2 {
  margin-left: 30px;
  margin-top: -15px;
}

.finacial_kpi_image-3 {
  margin-left: 20px;
  margin-top: -20px;
}

.finacial_kpi_image-target {
  margin-left: 20px;
  margin-top: 13px;
}
.finacial_kpi_image-actal {
  margin-left: 30px;
  margin-top: 13px;
}
.finacial_kpi_image-Revenue {
  margin-top: 5px;
  margin-left: 18px;
}
.finacial_kpi_image-Revenue-1 {
  margin-top: -25px;
  margin-left: 34px;
}
.finacial_kpi_revenue {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353843;
  opacity: 1;
  margin-left: 10px;
  margin-top: 20px;
}

.finacial_kpi_number_2_Revenue {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #f3be6a;
  opacity: 1;
  margin-left: 5px;
  margin-top: -5px;
  //margin-top: -15px;
}
.finacial_kpi_image-revenue {
  margin-top: 10px;
  margin-left: 20px;
}
.finacial_kpi_revenue-text {
  text-align: left;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #6e6e6e;
  opacity: 1;
  margin-left: 30px;
  margin-top: 10px;
}

.finacial_kpi_image-5 {
  width: 35px;
  height: 40px;
  margin-left: 30px;
  margin-top: 10px;
}

.finacial_kpi_progress_bar {
  margin-top: 5px;
  // width: 100px;
  margin-left: 10px;
  margin-right: 10px;
}
.finacial_kpi_progress_bar-1 {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 1px;
}

.finacial_kpi_progress_bar-2 {
  margin-right: 10px;
  margin-top: 5px;
  margin-left: 10px;
}

.finacial_kpi_head-text {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.width-20 {
  width: 23%;
}

.finacial_kpi_head_img-1 {
  margin-top: -52px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.finacial_kpi_head_img {
  margin-top: -5px;
  margin-right: 10px;
  margin-left: -5px;
  //width: 18px;
}

.finacial_kpi_head-text-1 {
  margin-left: 40px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.finacial_kpi_head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
