@import "../../stylesheets/robotoFonts";

.daily_shift_op_report {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}

.daily_op_wise_table{
  margin-top: -30px;
}




.daily_shift_op_report_div {
  margin-top: -25px;
  display: flex;
  padding: 2rem;
  // width: 50%;
  //justify-content: space-between;
}
.daily_shift_op_report_labels {
  display: flex;
}



.daily_shift_op_report_style_1 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 8px;
  margin-right: -98px;
}

.daily_shift_op_report_style {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 27px;
  margin-right: -102px;
}

.daily_shift_op_report_button_submit {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  // margin-top: 1px;
  margin-left: 25px;
  float: right;
}
.daily_shift_op_report_button-submit_1 {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
  float: right;
}

.daily_shift_op_report_search_input {
  width: 400px;
  margin-top: 1px;
  font-family: $font-name-Roboto-Regular;
  height: 30px;
  margin-left: 40px;
}

::placeholder {
  font-size: 13px;
  color: #acb0bc;
  padding-left: 12px;
  opacity: 1;
  letter-spacing: 0px;
}

.daily_shift_op_report_download {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  cursor: pointer;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}

.daily_shift_op_report_download_img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}
