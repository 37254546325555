@import "../../stylesheets/robotoFonts";

.tripsummary {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.ReactTable .rt-thead .rt-resizable-header {
  background-color: #eaedf1;
  color: #000 !important;
}
.ReactTable{
    margin-top: 3%;
    margin-left: 30px;
   
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}

// .downloadImgblue {
//   width: 28px;
//   height: 28px;
//   margin-left: 96%;
//   color: DodgerBlue;
// }
// .btn {
//   // background-color: DodgerBlue;
//   border: none;
//   color: DodgerBlue;
//   padding: 2px 2px;
//   cursor: pointer;
//   font-size: 20px;
//   //margin-left: 94%;
// }
// .msg {
//   text-align: right;
//   color: #57595d;
//   font-size: small;
//   margin-top: 10px;
//   margin-bottom: 1%;
//   font-weight: normal;
//   padding-right: 15px;
//   font-family: $font-name-Roboto-Regular;
// }


// .tripsummary-first-div {
//   margin-left: 10px;
//   margin-top: -20px;
//   display: flex;
//   padding: 2rem;
//   width: 50%;
//   justify-content: space-between;
// }
// .tripsummary-labels {
//   display: flex;
// }
// .tripsummary-labels_style {
//   display: flex;
//   font-family: $font-name-Roboto-Medium;
//   font-size: 15px;
//   margin-top: -5px;
//   margin-left: 10px;
// }

// .tripsummary-labels_style-1 {
//   display: flex;
//   font-family: $font-name-Roboto-Medium;
//   font-size: 15px;
//   margin-top: -5px;
// }
// .tripsummary-button-submit-kpi {
//   padding: 5px 5px;
//   //margin-left: -30px;
//   font-size: 14px;
//   margin-right: 20px;
//   background-color: #ED2541;
//   border: none;
//   outline: none;
//   border-radius: 3px;
//   color: #fff;
//   font-family: $font-name-Roboto-Bold;
//   cursor: pointer;
//   width: 120px;
//   font-weight: 500;
//   letter-spacing: 1px;
//   margin-top: 1px;
// }

// // .react-datepicker {
// //   // font-family: 'Red Hat Display', sans-serif !important;

// //   font-family: $font-name-Roboto-Bold;
// //   font-size: 10px !important;
// //   border: none !important;
// //   border-radius: 5px !important;
// //   height: 10%;
// //   text-align: center;
// //   // box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06) !important;
// // }





// .Reporting-Details-report {
//   // margin-left: 53px;
//    margin-top: -5px;
//    margin-bottom: 10px;
//    font-family: $font-name-Roboto-Regular;
//  }
//  .Reporting-Details-input{
//  width: 400px;
//   margin-top: 7px;
//   height: 30px;
//   margin-left: 70px;
//  }



 
//  .Reporting-Details-download{

//   margin-top: 5px;
//   margin-left: 30px;
//     font-size: 14px;
//     letter-spacing: 0px;
// color: #8997B5;
// opacity: 1;
//     font-family: $font-name-Roboto-Medium;
//  }


//  .Reporting-Details-download-img{
//   margin-left: 47px;
//     margin-top: -47px;
//  }



.reporting_details_labels{
  display: flex;
}
.reporting_details_labels_styles {
 // display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-left: 7px;
  margin-top: -5px;
  margin-right: -98px;
}

.reporting_details_labels_styles-1 {
  // display: flex;
   font-family: $font-name-Roboto-Medium;
   font-size: 15px;
   margin-left: 5px;
   margin-top: -5px;
   margin-right: -104px;
   //margin-top: -5px;
 }

.reporting_details_button-submit-kpis {
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 25px;
 // margin-right: 10px;
}

.reporting_details_button-submit-kpis-1 {
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
 // margin-right: 10px;
}

// .react-datepicker {
//   // font-family: 'Red Hat Display', sans-serif !important;

//   font-family: $font-name-Roboto-Bold;
//   font-size: 10px !important;
//   border: none !important;
//   border-radius: 5px !important;
//   // height: 10%;
//   text-align: center;
//   //margin-top: -4px;
//   height: 100%;
//   width: 100%;

//   float: right;
//   margin-right: 6px;
//   //margin-top: -30px;
//   position: relative;
//   z-index: 2;
//   // box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06) !important;
// }

// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker__input-container input {
//   display: block;
//   width: 155px;
//   text-align: center;
//   //padding: 3px 3px;
//   // margin-right: 13px;
//   //margin-left: -5px;
// }

.reporting_details_report {
  // margin-left: 53px;
  //margin-top: -5px;
  margin-bottom: 10px;
  font-family: $font-name-Roboto-Regular;
}
.reporting_details_input {
  width: 400px;
 // margin-top: 6px;
  height: 30px;
  margin-left: 48%;
}

.reporting_details_download {
  margin-top: 5px;
  margin-left: 210px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}

.reporting_details_download-img {
  margin-left: 10px;
  margin-top: -1px;
}



