

@import "../../stylesheets/robotoFonts";
.mapaction-right {
  display: flex;
  margin-left: 20px;
  // margin-top: 16px;
  margin-top: 10px;
  // margin-left: 70%;
  height: 100%;


  .mapactiontwo-head {
    margin-left: -30px;
    font-size: 15px;
    margin-right: 24px;
    margin-top: 2px;
    white-space: nowrap;
    //padding-right: 105px;
    font-family: $font-name-Roboto-Regular;
    font-weight: 700;
    font-variant: normal;
    letter-spacing: 0px;
    color: #073a81;
    text-transform: capitalize;
    opacity: 1;
  }
  .mapaction-breakdown-para {
    margin-left: 10px;
    margin-top: -20px;
    color: #434343;
    font-size: 14px;
    font-family: $font-name-Roboto-Regular;
  }
  .mapaction-available {
    margin-left: 30px;
  }
  .mapaction-available-para {
    margin-left: 10px;
    margin-top: -20px;
    color: #434343;
    font-size: 14px;
    font-family: $font-name-Roboto-Regular;
  }
  .mapaction-Occupied {
    margin-left: 30px;
  }
  .mapaction-Occupied-para {
    margin-left: 10px;
    margin-top: -20px;
    color: #434343;
    font-size: 14px;
    font-family: $font-name-Roboto-Regular;
  }
  .mapaction-img {
    margin-left: -15px;
    //margin-right: 5px;
  }

  .counter {
    background-color: yellow;
    border-radius: 30px;
    font-size: 12px;
    // margin: 0 0.5rem 0.5rem;
    text-align: center;
    padding: 4px 10px;
    //width: 20px;
    //text-align: center;
    //float: right;
    //margin-top: -22px;
    margin-left: 3px;
    //margin-right: 2px;
  }
  .counter-one {
    background-color: yellow;
    border-radius: 30px;
    font-size: 12px;
    //margin: 0 0.5rem 0.5rem;
    text-align: center;
    padding: 4px 7px;
   // width: 9px;

    //text-align: center;
    //float: right;
    //margin-top: -22px;
    margin-left: 3px;
    margin-right: 2px;
  }
}

