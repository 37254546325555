@import "../../stylesheets/robotoFonts";

.efficiency_report {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.efficiency_report-backward{
  display: flex;
  margin-top: 20px;
}

.efficiency_report-downloads {
  margin-top: 5px;
  margin-left: 1080px;
  font-size: 14px;
  letter-spacing: 0px;
  cursor: pointer;
  color: #8997b5;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}

.efficiency_report-download-imgs {
  margin-left: 10px;
  margin-top: -1px;
  cursor: pointer;
}
.charge_summary_div {
  margin-top: -25px;
  display: flex;
  padding: 2rem;
  
}
.efficiency__report_table{
  margin-top: 0px;

}
.efficiency_report_submit{
    background-color: #e63e33;
      width: 103px;
      height: 34px;
      margin-left: 5px;
      border-radius: 3px;
      border: none;
      /* UI Properties */
      text-align: center;
      letter-spacing: 0px;
      color: #ffffff;
      font-size: 14px;
      text-transform: capitalize;
      border-radius: 6px;
      opacity: 1;
      margin-top: 8px;
}