@import "../../stylesheets/robotoFonts";
.driver-details-text {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}

.driver-details-action {
  color: #da3d48;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Bold;
  text-decoration: none;
}
.driver-details-action:hover {
  color: red;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-color: #eaedf1;
  // padding: 21px;
  mix-blend-mode: normal;
  text-align: center;
  opacity: 1;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  font-family: $font-name-Roboto-Medium;
}

.driver-details-search {
  width: 400px;
  height: 35px;
  margin-top: 10px;
  margin-left: 50px;
  border-radius: 5px;
  border: 1px solid gray;
}

.records-info-2 {
  margin-left: 43px;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker__input-container input {
//   display: block;
//   //width: 405px;
//   text-align: center;
//   font-family: $font-name-Roboto-Regular;
//   //padding: 3px 3px;
//   // margin-right: 13px;
//   //margin-left: -5px;
// }

.driver_details_div {
  margin-top: -25px;
  display: flex;
  padding: 2rem;
  // width: 50%;
  //justify-content: space-between;
}
.driver_details_div_labels {
  display: flex;
}

.driver_details_div_labels_style-1 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 12px;
  margin-right: -98px;
}

.driver_details_div_labels_style {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 27px;
  margin-right: -103px;
}
.driver_details_div_button-submit {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  // margin-top: 1px;
  margin-left: 25px;
  float: right;
}
.driver_details_div_button-submit-1 {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 25px;
  // margin-top: 1px;
  //margin-left: -55px;
  //margin-right: 10px;
  float: right;
}

.driver_details_search-input {
  width: 400px;
  margin-top: 1px;
  font-family: $font-name-Roboto-Regular;
  height: 30px;
  margin-left: 10px;
}

::placeholder {
  font-size: 13px;
  color: #acb0bc;
  padding-left: 12px;
  opacity: 1;
  letter-spacing: 0px;
}

.driver_details-download {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  cursor: pointer;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}

.driver_details-download-img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}
