@import "../../stylesheets/robotoFonts";

.container-one {
  margin-top: 17px;
  margin-left: 50px;
  margin-right: 41px;

  .batery-graph-line {
    // margin-top: 50px;
    width: 100%;
    margin: -0.3rem 0;
  }
  .hr {
    margin: 0.7rem 0 !important;
  }

  //margin-left: 20px;
  .lp_review_err_btn_one {
    width: 90px;
    padding-top: 7px;
    //background-color: #0086d0;
    height: 30px;
    //width: 13%;
    padding-left: 15px;
    display: flex;
    margin-top: -32px;
    margin-left: 87%;
    //text-align: center;
    border: 1px solid black;
    cursor: pointer;
    border-radius: 20px;
    // text-align: left;
    font-size: 9px;
    font-weight: 500;
    //text-align: center;
    font-family: $font-name-Roboto-Medium;
    letter-spacing: 1px;
    //lettercolor: #353844;
    border-color: #353844;
    text-transform: uppercase;
    opacity: 1;
  }
  .lp_next_png_one {
    height: 6px;
    //float: right;
    margin-top: 5px;
    padding-left: 2px;
    padding-bottom: 1px;
    //padding-right: 5px;
    //color: black;
  }
  h4 {
    text-align: left;
    font-size: 16px;
    font-family: $font-name-Roboto-Bold;
    font-weight: 800;
    font-variant: normal;
    letter-spacing: 0.32px;
    color: #083a81;
    text-transform: uppercase;
    opacity: 1;
    //margin-top: 10px;
    padding-top: 11px;
  }
}


.graph_legend {
  top: 50px;
  left: 50px;
  width: 247px;
  height: 30px;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #dbdee8;
  border-radius: 5px;
  opacity: 1;
}

.dot1 {
  height: 8px;
  width: 8px;
  background-color: #a7d8fb;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  margin-left: 8px;
}

.dot2 {
  height: 8px;
  width: 8px;
  background-color: #6bb13f;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  margin-left: 8px;
}

.dot3 {
  height: 8px;
  width: 8px;
  background-color: #f5c06b;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  margin-left: 8px;
}

.dot4 {
  height: 8px;
  width: 8px;
  background-color: #e63e33;
  border-radius: 50%;
  display: inline-block;
  font-size: 10px;
  margin-left: 8px;
}

.colorText {
  font-family: $font-name-Roboto-Regular;
  font-size: 12px;
}
