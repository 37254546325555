@import "../../stylesheets/robotoFonts";

.trip-details {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.ReactTable .rt-thead .rt-resizable-header {
  background-color: #eaedf1;
  color: #000 !important;
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}
.ReactTable .rt-tbody {
  overflow-x: hidden;
}

.trip_details_div {
  margin-top: -28px;
  margin-left: 10px;
  display: flex;
  padding: 2rem;
  width: 50%;
  justify-content: space-between;
}
.trip_details_div_labels {
  display: flex;
}

label {
  display: flex;
  width: 180px;
  //margin-right: 70px;
}
.trip_details_div_labels_style {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 10px;
  margin-right: -100px;
 
}
.trip_details_div_labels_style-1 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-right: -90px;
}
.trip_details_div_button-submit-kpi {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 175px;
 // margin-right: 10px;
}

.trip_details_div_button-submit-kpi-1 {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
 // margin-right: 10px;
}

.search-trip-report {
  // margin-left: 53px;
  margin-top: -5px;
  //margin-bottom: 10px;
  font-family: $font-name-Roboto-Regular;
}
.search-trip-report-input {
  width: 400px;
  margin-top: 6px;
  height: 30px;
  margin-left: 51px;
}

.search-trip-report-download {
  margin-top: 5px;
  margin-left: 7px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.search-trip-report-download-img {
  margin-left: 47px;
  margin-top: -42px;
}
.trip-report-table{
  margin-top: -40px;
}




