@import "../../stylesheets/robotoFonts";

.trip-popup-update {

  .modal-body {
    margin-top: -25px;
    //margin-right: 1%;
  }

  .modal-title {
    //text-align: center;
    //margin-left: 60%;
    font-family: $font-name-Roboto-Bold;
    font-size: 16px;
    //margin-left: 646px;
    font-weight: 700;
    font-variant: normal;
    letter-spacing: 0.32px;
    color: #083a81;
    text-transform: uppercase;
    opacity: 1;
  }

  .close {
    color: #aaa;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    position: absolute;
    right: 30px;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }
  #contained-modal-title-vcenter {

    /* UI Properties */
    margin-left: 60%;
    text-align: center;
    //font: normal normal bold 16px/19px Roboto;
    font-family: $font-name-Roboto-Bold;
    font-size: 16px;
    font-weight: 700;
    font-variant: normal;
    letter-spacing: 0.32px;
    color: #083a81;
    text-transform: uppercase;
    opacity: 1;
  }
}

.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-color: #eaedf1;
  // padding: 21px;
  mix-blend-mode: normal;
  text-align: center;
  opacity: 1;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  font-family: $font-name-Roboto-Medium;
}
.ReactTable .rt-tbody {
  text-align: center;
  min-width: 100px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  // overflow-x: none;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  //overflow-x:
  overflow-x: scroll;
}
.ReactTable .-pagination .-btn {
  font-size: 1em;
}
.title {
  font-family: $font-name-Roboto-Bold;
  font-size: 19px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0.32px;
  color: #083a81;
  margin-left: 420px;
}

.modal-90w {
  width: 90%;
  max-width: 2500px;
  height: 90%;
  max-height: 2500px;
}

.close {
  color: black;
  //opacity: 1;
  font-weight: 500;
  border: none;
  background-color: white;
  font-size: 35px;
}

