@import "../../stylesheets/robotoFonts";

.charging-summary {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}

.charge-summary-table {
  margin-top: -24px;
  //margin-left: 26px;
  //margin-right: 33px;
}

.ReactTable .rt-thead.-header {
  position: sticky;
  top: 0;
  //z-index: 10;
  background-color: #fff;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-color: #eaedf1;
  padding: 8px;
  mix-blend-mode: normal;
  text-align: center;
  opacity: 1;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: $font-name-Roboto-Medium;
  
  //  overflow-y: visible;
}

.ReactTable .rt-tbody {
  text-align: center;
  overflow-y: visible;
  scrollbar-color: red;
  overflow: initial !important;
}

.ReactTable {
  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: url(../../assets/images/arrow-up/up.png);
      float: right;
      margin-right: 16px;
    }
  }
  .-sort-asc {
    box-shadow: none !important;
    &:before {
      // content: "▲";
      content: url(../../assets/images/arrow-up/up.png);
      float: right;
      margin-right: 16px;
      transform: rotate(180deg);
    }
  }
}

.loader-overlay {
  position: fixed;
  top: 40%;
  right: 0;
  bottom: 0;
  left: 0;
  //font-size: 25px;
  // background: rgba(0, 0, 0, 0.2);
  // z-index: 1;
}

.loader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.charge_summary_div {
  margin-top: -25px;
  display: flex;
  padding: 2rem;
  // width: 50%;
  //justify-content: space-between;
}
.charge_summary_div_labels {
  display: flex;
}
.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  //width: 160px;
  width: unset;
  height: 27px;
  font-family: $font-name-Roboto-Regular;
  //border: none;
  background: #fff;
  text-align: center;
  cursor: pointer;
}

.react-datepicker__input-time-container,
.react-datepicker-time__input-container,
.react-datepicker-time__input-container input {
  width: unset;
  //color:red;
  font-family: $font-name-Roboto-Medium;
}

.charge_summary_div_labels_style-1 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 8px;
  margin-right: -98px;
}

.charge_summary_div_labels_style {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 27px;
  margin-right: -102px;
}

.charge_summary_div_button-submit {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  // margin-top: 1px;
  margin-left: 25px;
  float: right;
}
.charge_summary_div_button-submit-1 {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
  float: right;
}

.charge_summary_search-input {
  width: 400px;
  margin-top: 1px;
  font-family: $font-name-Roboto-Regular;
  height: 30px;
  margin-left: 40px;
}

::placeholder {
  font-size: 13px;
  color: #acb0bc;
  padding-left: 12px;
  opacity: 1;
  letter-spacing: 0px;
}

.charge_summary-download {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  cursor: pointer;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}

.charge_summary-download-img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}
