@import "../../stylesheets/robotoFonts";
.daily-operation-text {
  font-size: 13px;
  font-family: $font-name-Roboto-Bold;
  //text-align: center;
  margin-top: 7px;
}

.DailyOperationReport {
  margin-top: 1px;
  margin-left: 10px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}

.DailyOperationReport-download {
  margin-top: 25px;
  margin-left: 78%;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.DailyOperationReport-download-img {
  margin-left: 6px;
  margin-top: -1px;
  cursor: pointer;
}

.daily_operation_report_div {
  margin-top: -25px;
  display: flex;
  padding: 2rem;
  margin-left: -20px;
  // width: 50%;
  //justify-content: space-between;
}
.daily_operation_report_div_labels {
  display: flex;
}
.daily_operation_report_div_labels_style-1 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 22px;
  margin-right: -98px;
 
}

.daily_operation_report_div_labels_style-2 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 27px;
  margin-right: -104px;
}

.daily_operation_report_div_button-submit {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  // margin-top: 1px;
  margin-left: 25px;
  float: right;
}
.daily_operation_report_div_button-submit-1 {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
  // margin-top: 1px;
  //margin-left: -55px;
  //margin-right: 10px;
  float: right;
}

.daily-operation-backward {
  margin-top: 20px;
  display: flex;
  margin-left: -10px;
}

.hg-34 {
  height: 34px;
}

.hg-36 {
  height: 36px;
}

.hg-12 {
  height: 12px;
}
