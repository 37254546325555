@import "../../stylesheets/robotoFonts";

.googleMapWidth {
  width: 103.5%;
  margin-left: -37px;
}
.modal {
  background: #ffffff 0% 0% 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 26px #00000029;
  border-radius: 5px;
  opacity: 1;
}
.popup-veh-no {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}

.modal-title {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}

.closebutton span {
  margin-left: 345px;
  cursor: pointer;
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 12px;
  letter-spacing: 0px;
  color: #da3d48;
  text-transform: uppercase;
  opacity: 1;
}
.vehicle-no {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Regular;
  color: #434343;
  opacity: 1;
  margin-left: 80px;
  margin-top: -42px;
  margin-bottom: 12px;
}
.reg {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Bold;
  color: #18397d;
  opacity: 1;
  margin-left: 80px;
  margin-top: -20px;
  padding-top: 3px;
}

.skyline {
  text-align: left;
  font-size: 10px;
  font-family: $font-name-Roboto-Regular;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
  // margin-left: 10px;
  //margin-top: -40px;
}
.time-fullcharge {
  text-align: left;
  font-size: 12px;
  font-weight: 500;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}
.fullcharge {
  margin-top: -10px;
}

.map-button {
  float: left;
  margin-left: 5px;
  //margin-top: -10px;
  margin-bottom: 20px;
  .map-btn {
    height: 30px;
    width: 90%;
    padding-left: 14px;
    //background-color: white;
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #000000;
    cursor: pointer;
    border-radius: 20px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    box-shadow: 0px 0px 26px #00000029;
    text-align: center;
    font-family: $font-name-Roboto-Regular;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    // margin-top: -100px;
    margin-left: 14px;
  }

  .map-button-png {
    height: 6px;
    padding-left: 2px;
    padding-bottom: 1px;
  }
}

.table-col {
  margin-top: 0%;
}
table {
  border-collapse: collapse;
}
table td {
  border: none !important;
}

tr:nth-child(odd) {
  background-color: #f5f5f5;
  width: 15px;
  height: 10px;
}

tr {
  margin-top: -10px;
}

th,
td {
  text-align: left;
  //border: 1px solid white;
  // border: none;
  padding: 4px 5px 5px 5px;
}

.td-1 {
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0px;
  color: #434343;
  opacity: 1;
}

.td-2 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
  letter-spacing: 0px;
  color: #787e8f;
  opacity: 1;
}

.time-to-charge-text {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}
