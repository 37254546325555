@import "../../stylesheets/robotoFonts";
.right-border{
    border: 1px solid gray;
}

.switch-field-fleet-uptime {
    font-family: $font-name-Roboto-Regular;
    //padding: 40px;
    margin-left: 20%;
    margin-top: -16px;
  }
  .switch-img {
    margin-left: -10px;
  }
  .switch-img-1 {
    margin-right: 4px;
  }
  .switch-title {
    font-weight: bold;
    margin-bottom: 6px;
  }
  
  .switch-field-fleet-uptime input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    border: 0;
    overflow: hidden;
  }
  
  .switch-field-fleet-uptime label {
    display: inline-block;
    width: 20vh;
    background-color: white;
    color: rgba(0, 0, 0, 0.6);
    font-size: 80%;
    font-weight: normal;
    text-align: center;
    text-shadow: none;
    padding: 6px 14px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
  }
  
  .switch-field-fleet-uptime label:hover {
    cursor: pointer;
  }
  
  .switch-field-fleet-uptime input:checked + label {
    background-color: #18397d;
    color: #fff;
    box-shadow: none;
  }
  
  .switch-field-fleet-uptime label:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  
  .switch-field-fleet-uptime label:last-of-type {
    border-radius: 0 4px 4px 0;
  }

  .mt-10{
    margin-top: 9.3%;
  }


  .fleet-health_report_div_labels{
    display: flex;
   
  }
  
  
  .fleet_health_report_div_labels_style {
    display: flex;
    font-family: $font-name-Roboto-Medium;
    font-size: 15px;
    margin-top: -5px;
    margin-left: 10px;
    margin-right: -97px;
  }
  .fleet_health_report_div_labels_style-1 {
    display: flex;
    font-family: $font-name-Roboto-Medium;
    font-size: 15px;
    margin-top: -5px;
    margin-left: 10px;
    margin-right: -103px;
  }
  
  .fleet_health_report_div_button-submit {
    padding: 4px 5px;
    background-color: #ed2541;
    border: none;
    outline: none;
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    font-family: $font-name-Roboto-Bold;
    cursor: pointer;
    width: 120px;
    font-weight: 500;
    letter-spacing: 1px;
    //  margin-top: 18px;
    margin-left: 25px;
    float: right;
  }

  .fleet_health_div_button-submit-1 {
    padding: 4px 5px;
    background-color: #ed2541;
    border: none;
    outline: none;
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    font-family: $font-name-Roboto-Bold;
    cursor: pointer;
    width: 120px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 20px;
    float: right;
  }