@import "../../stylesheets/robotoFonts";
.refresh-toggler{
    margin-top: 6px;
    display: flex;
    padding: 1rem;
    margin-left: 20px;
    // border: 1px solid grey;
    margin-right: 2rem;
    &-text{
        margin-right: 2rem;
        font-family: $font-name-Roboto-Bold;
        font-size: 0.9rem;
        font-weight: bold;
        color: #222222;
    }
    &-on,&-off{
        margin-right: 1.1rem;
        font-family: $font-name-Roboto-Bold;
        font-size: 0.9rem;
        color: #222222;
        font-weight: bold;
        cursor: pointer;
        // border: 1px solid grey;
    }
    &-off-state{
        margin-right: 1.1rem;
        font-family: $font-name-Roboto-Bold;
        font-size: 0.9rem;
        color: red;
        font-weight: bold;
        cursor: pointer;
    }
    &-on-state{
        margin-right: 1.1rem;
        font-family: $font-name-Roboto-Bold;
        font-size: 0.9rem;
        font-weight: bold;
        color: #5fdba7;
        cursor: pointer;
    }
}
.off{
    border: 1px solid red;
}