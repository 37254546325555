@import "../../stylesheets/robotoFonts";

.speed_band_content{
display: flex;
gap: 60px;
}
.speed_band_1{
    display: block;
    margin-left: 70px;
}
.speed_band_value{
    margin-left: 30px;
    margin-top: 5px;
    margin-bottom: 9px;
    letter-spacing: 0px;
    color: #767880;
    opacity: 1;
    font-size:14px;
    font-family: $font-name-Roboto-Medium;
    
}
.speed_band_button{
    padding-top: 9px;
    margin-top: 20px;
    width: 91px;
    height: 35px;
    border-radius: 5px;
    cursor:text;
    background-color: #f4cf92;
    border: none;
}
.speed_band_button-1{
    padding-top: 9px;
    margin-top: 20px;
    width: 91px;
    height: 35px;
    border-radius: 5px;
    background-color: #f8aa43;
    border: none;
}
.speed_band_button-2{
    padding-top: 9px;
    margin-top: 20px;
    width: 91px;
    height: 35px;
    border-radius: 5px;
    background-color: #ee8022;
    border: none;
}
.speed_band_button-3{
    padding-top: 9px;
    margin-top: 20px;
    width: 91px;
    height: 35px;
    border-radius: 5px;
    background-color: #e25d02;
    border: none;
}
.speed_band_button_value{
    font-size: 20px;
    font-family: $font-name-Roboto-Bold;
    margin-top: -6px;
   
    color: white;
}

// .speed_band_text_end{
//     font-size: 12px;
//     font-family: $font-name-Roboto-Medium;
//     margin-left: 46%;
//    // margin-top: 0px;
// }
