@import "../../stylesheets/robotoFonts";

.tripmaster {
  margin-top: 15px;
  margin-left: 35px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.trip_updation_popup_title{
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
}
// .breakdownsummary {
//   margin-top: 20px;
//   margin-left: 50px;

//   /* UI Properties */
//   text-align: left;
//   font-family: $font-name-Roboto-Bold;
//   font-size: 22px;
//   font-weight: 700;
//   font-variant: normal;
//   letter-spacing: 0px;
//   color: #073a81;
//   text-transform: capitalize;
//   opacity: 1;
// }
.ReactTable .rt-thead .rt-resizable-header {
  background-color: #eaedf1;
  color: #000 !important;
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}

.trip-master-subhead {
  display: flex;
  margin-top: -40px;
  margin-left: 40px;

}

.download-upload {
  margin-left: 20px;
  margin-top: 30px;
  cursor: pointer;
}


.search-trip-master-report {
 // margin-left: 47%;
  display: flex;
  font-family: $font-name-Roboto-Regular;
  margin-left: -20px;
}



.search-trip-master-input {
  width: 400px;
  height: 30px;
  margin-top: 30px;
}


.records-info{
 // margin-left: 40px;
 // margin-top: -20px;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.Trip-Updation_report-download {
  margin-top: 30px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.Trip-Updation_report-download-img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}

.trip-updation-submit-kpis {
  margin-top: 30px;
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 140px;
  font-weight: 500;
  letter-spacing: 1px;
  //margin-left:-280px;
  margin-right: 25px;
}

.col_50_trip_update {
  width: 86%;
  float: left;
  margin-right: 1%;
  margin-left: 1%;
}
.col_100_trip_update {
  width: 100% !important;
  float: left;
  margin-left: 2%;
  display: inline-flex !important;

}
.button_trip_updation{
  margin-top: 2%;
  margin-bottom: 6%;
  width: 40%;
  margin-left: 45.5%;
  background-color: #0089d3;
  color: #fff;
  height: 2.5rem;
  padding: 0 2.6rem;
  border: none;
  line-height: 1;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 0.35rem;
  font-family: $font-name-Roboto-Regular;
}