@import "../../stylesheets/robotoFonts";

.card {
  color: white;
  width: 128px;
  height: 40px;
  margin-left: 10px;
  margin-top: -10px;
  //margin-bottom: 5px;
  font-family: $font-name-Roboto-Regular;
  border-radius: 50px;
  cursor: pointer;
}
.all_countNumberBox {
  background-color: #fff;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 0.5rem 0.5rem;
  text-align: center;
  padding: 5px;
  width: 30px;
  border: black;
  //text-align: center;
  float: right;
  margin-top: -39px;
  margin-left: 85px;
  cursor: pointer;
}
.moving_countNumberBox {
  background-color: #fff;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 0.5rem 0.5rem;
  text-align: center;
  padding: 5px;
  width: 30px;
  //text-align: center;
  float: right;
  margin-top: -39px;
  margin-left: 85px;
  cursor: pointer;
}
.mapaction-cardtext {
  margin-left: 13px;
  margin-top: 10px;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
}

.stopped_countNumberBox {
  background-color: #fff;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 0.5rem 0.5rem;
  text-align: center;
  padding: 5px;
  width: 30px;
  //text-align: center;
  float: right;
  margin-top: -39px;
  margin-left: 85px;
  cursor: pointer;
}

.all_countNumberBox {
  background-color: #fff;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 0.5rem 0.5rem;
  text-align: center;
  padding: 5px;
  width: 30px;
  height: 30px;
  border: 1px solid black; //modified
  //text-align: center;
  //float: right;
  margin-top: -39px;
  margin-left: 85px;
  cursor: pointer;
}

.mapaction-cardtext-2 {
  margin-left: 34px;
  color: blue;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 600;
  color: black;
  font-family: $font-name-Roboto-Regular;
}

.reachable_countNumberBox {
  background-color: #fff;
  border-radius: 25px;
  font-size: 12px;
  margin: 0 0.5rem 0.5rem;
  text-align: center;
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  //text-align: center;
  //float: right;
  margin-top: -39px;
  margin-left: 90px;
}

.mapaction-cardtext-1 {
  color: white;
  margin-left: 8px;
  margin-top: 10px;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
}
.mapactionone-head {
  margin-left: -10px;
  font-size: 15px;
  white-space: nowrap;
  font-family: $font-name-Roboto-Regular;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
  cursor: pointer;
}

.buttonTrue {
  color: white;
  cursor: pointer;
}

.buttonFalse {
  //color: black;
  cursor: pointer;
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
  border-radius: 20px;
  //box-shadow:px 0px 0px 0px blue;
}

.bg-shadow {
  box-shadow: 1px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2) !important;
}
#summaryExcelDownloadBtn {
  box-shadow: 1px 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.2) !important;
}

.headWrap {
  border-radius: 50px !important;
}
.headSelected {
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.1) !important;
}
.allbg {
  background-color: white;
}
.runningBg {
  background-color: #7aae4f;
}

.stoppedBg {
  background-color: #da3d48;
}

.disconnectedBg {
  background-color: #8c98b2;
}


.spareBg{
  background-color: rgb(180, 180, 7);
}