@import "../../stylesheets/robotoFonts";

.width-25 {
  width: 49%;
}
.sustainance_kpi_head-text {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.width-20 {
  width: 23%;
}
.sustainance_kpi_head_img-1 {
  margin-top: -52px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.sustainance_kpi_head_img {
  margin-top: -5px;
  margin-right: 10px;
  margin-left: -5px;
  //width: 18px;
}
.sustainance_kpi_head-text-1 {
  margin-left: 40px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}
.sustainance_kpi_head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.sustainance_kpi_text {
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -25px;
  margin-left: 40px;
  text-align: left;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}
.sustainance_kpi_images {
  width: 15px;
  margin-top: -10px;
  margin-left: 20px;
}
.sustainance_kpi_footer {
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
}
.sustainance_kpi_text-1 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 50px;
  margin-left: 10px;
}
.bg-gray {
  background: #dbdee8 0% 0% no-repeat padding-box;
}
.sustainance_kpi-text_body {
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 20px;
  letter-spacing: 0px;
  color: #353843;
  opacity: 1;
  margin-top: -40px;
  margin-left: 60px;
}

.sustainance_kpi_image {
  width: 35px;
  height: 29px;
  margin-top: 40px;
  margin-left: 10px;
}

.sustainance_kpi-text_body_1 {
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 13px;
  letter-spacing: 0px;
  color: #6e6e6e;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 60px;
  margin-top: -10px;
}

.sustainance_kpi_switch-field {
  font-family: $font-name-Roboto-Regular;
  //padding: 40px;
  margin-left: 68%;
  margin-top: -40px;
}
.switch-img {
  margin-left: -10px;
}
.switch-img-1 {
  margin-right: 4px;
}
.switch-title {
  font-weight: bold;
  margin-bottom: 6px;
}

.sustainance_kpi_switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  //height: 1px;
  //width: 1px;
  border: 0;
  overflow: hidden;
}

.sustainance_kpi_switch-field label {
  display: inline-block;
  width: 13vh;
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  font-size: 80%;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}

.sustainance_kpi_switch-field label:hover {
  cursor: pointer;
}

.sustainance_kpi_switch-field input:checked + label {
  background-color: #18397d;
  color: #fff;
  box-shadow: none;
}

.sustainance_kpi_switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}

.sustainance_kpi_switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}
