@import "../../stylesheets/robotoFonts";
.fleet-uptime-current-search {
  width: 400px;
  height: 31px;
  margin-top: 1px;
  margin-left: 16px;
  border-radius: 5px;
  border: 1px solid gray;
}
.fleet-uptime-current-div {
  display: flex;
}
.fleet-uptime-current_download {
  margin-top: 9px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Regular;
}
.fleet-uptime-current-img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}
