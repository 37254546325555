@import "../../stylesheets/robotoFonts";
.safty_kpi_header{
  margin-top: 1px;
  margin-left: 10px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Medium;
  font-size: 20px;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.safety_kpi_text {
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -25px;
  margin-left: 40px;
  text-align: left;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

// .safety_kpi_image {
//   width: 15px;
//   margin-top: -10px;
//   margin-left: 20px;
// }

.safety_kpi_footer {
  text-align: left;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-left: 6px;
}

.safety_kpi_text-2 {
  text-align: left;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: -60px;
  margin-left: 35px;
}

.safety_kpi_text-3 {
  text-align: center;
  margin-left: -15px;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  margin-top: -10px;
}

.safety_kpi_text-1 {
  text-align: left;
  font-size: 13px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 7px;
  margin-left: 35px;
}

.bg-gray {
  background: #dbdee8 0% 0% no-repeat padding-box;
}

.safety_kpi_head-text {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.widthy-20 {
  width: 23%;
}

.safety_kpi_head_img-1 {
  margin-top: -62px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.safety_kpi_head_img {
  margin-top: -5px;
  margin-right: 10px;
  margin-left: -5px;
  //width: 18px;
}

.safety_kpi_head-text-1 {
  margin-left: 40px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.safety_kpi_head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.lotty-player-safty {
  margin-top: -10px;
}

.lotty-player-compliance-11 {
  margin-top: 40px;
  height: 70px;
  margin-left: 2px;
}

.safety_kpi-btns {
  height: 28px;
  width: 29%;
  // padding-left: 14px;
  background-color: transparent;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 10px;
  // text-align: left;
  font-size: 9px;
  font-weight: 700;
  font-variant: normal;
  text-align: center;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 1px;
  color: black;
  text-transform: uppercase;
  opacity: 1;
  margin-top: -10px;
  margin-left: 40px;
  //margin-top: -49px;

  .safety_kpi-button-png {
    height: 6px;
    padding-left: 2px;
    padding-bottom: 1px;
  }
}

.safety_kpi-btns:hover {
  background-color: #ed2541;
  color: white;
}
