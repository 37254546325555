@import "../../stylesheets/robotoFonts";

.charging_infrastructure_head {
  margin-left: 10px;
  text-align: center;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.head-mydashboard {
  margin-top: 10px;
  margin-left: 33px;
  //display: flex;
  /* UI Properties */
  text-align: left;
  font-size: 16px;
  font-family: $font-name-Roboto-Bold;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.depot-chargers-subtext {
  margin-left: 69.5%;
  //text-align: left;
  font-size: 16px;
  font-family: $font-name-Roboto-Bold;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.depot-chargers-subtext-1 {
  margin-left: 66.5%;
  //text-align: left;
  font-size: 16px;
  font-family: $font-name-Roboto-Bold;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
// ::-webkit-scrollbar {
//   //background-color: white;
//   display: none;
//   overflow: hidden;
//   }

// .mydashboard{
//   -ms-overflow-style: none;
// }
// ::-webkit-scrollbar {
//   display: none;
// }

.mydashboard {
  overflow: hidden;
  overflow-x: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 13px;
  height: 14px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #8c98b2;
  border-radius: 10px;
  border: 3px solid #ffffff;
}
