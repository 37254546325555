@import "../../stylesheets/robotoFonts";

.trip-popup-update {

  .modal-body {
    margin-top: -25px;
  }

  .close {
    color: #aaa;
    font-size: 14px;
    font-weight: bold;
    height: 30px;
    position: absolute;
    right: 30px;
  }

  .closebtn {
    margin-left: 15px;
    color: white;
    font-weight: bold;
    float: right;
    font-size: 22px;
    line-height: 20px;
    cursor: pointer;
    border: 1px solid white;
    transition: 0.3s;
  }

  .closebtn:hover {
    color: black;
  }

}

.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-color: #eaedf1;
  // padding: 21px;
  mix-blend-mode: normal;
  text-align: center;
  opacity: 1;
  color: #000;
  font-size: 12px;
  font-weight: 400;
  font-family: $font-name-Roboto-Medium;
}
// .ReactTable .rt-th.-sort-asc .rt-resizer:before {
//   background-image: url("../../assets/images/arrow-up/up.png");
// }

.ReactTable .rt-tbody {
  text-align: center;
  min-width: 100px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  // overflow-x: none;
}
.ReactTable .rt-table {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  //overflow-x:
  overflow-x: scroll;
}
.ReactTable .-pagination .-btn {
  font-size: 1em;
}

.modal-90w {
  width: 90%;
  max-width: 2500px;
  //height: 1500px !important;
}

.close {
  color: black;
  //opacity: 1;
  border: none;
  background-color: white;
  font-size: 35px;
  font-weight: 500;
}
