@import "../../stylesheets/robotoFonts";

.w-2 {
  width: 20%;
}

.fleet-health-head {
  cursor: pointer;
  background: transparent linear-gradient(180deg, #073a81 0%, #041d41 100%) 0%
    0% no-repeat padding-box;
  box-shadow: inset 0px 3px 6px #000000bf;
  padding-bottom: 5px;
}
.fleet-health-backward {
  display: flex;
  margin-top: 20px;
  //margin-left: 20px;
}
// .hr-right{
//     border-right-width:1px solid  gray
// }

.fleet-health-heading {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
  // margin-top: 20px;
  margin-left: 15px;
  //margin-top: 15px;
}

.w-2 {
  width: 24%;
}

.fleet-health-head {
  display: flex;
}

.fleet-health-head-text {
  text-align: center;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 8px;
  margin-left: 20px;
}

.fleet-health-head-number {
  //text-align: center;
  font-size: 24px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-left: -60px;
  margin-top: 21px;
}

.border-rt {
  border-right: 1px solid #d1d2d6;
}

.bg-red {
  background-color: #ecedf2;
}

.fleet-health-image {
  width: 32px;
  height: 30px;
  margin-top: 16px;
  margin-left: 100px;
}

.fleet-health-image-last {
  width: 32px;
  height: 40px;
  margin-top: 16px;
  margin-left: 100px;
}

.fleet-health-left-no {
  text-align: left;
  font-size: 32px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin-left: 65px;
  margin-top: -5px;
}
.fleet-health-left-no:hover {
  color: green;
}
.fleet-health-right-no:hover {
  color: green;
}

.fleet-health-right-no {
  text-align: left;
  font-size: 32px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
  margin-top: -5px;
  margin-left: 40px;
}

.fleet-health-left-text {
  text-align: center;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353639;
  text-transform: uppercase;
  opacity: 1;
  margin-top: -21px;
  margin-left: -15px;
}

.fleet-health-right-text {
  text-align: center;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353639;
  text-transform: uppercase;
  opacity: 1;
  margin-top: -24px;
  margin-left: -50px;
}

.fleet-health-div {
  display: flex;
  margin-top: 20px;
  margin-left: 30px;
}

.fleet-health_report_div_labels {
  display: flex;
}

.fleet_health_report_div_labels_style {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 10px;
  margin-right: -97px;
}
.fleet_health_report_div_labels_style-1 {
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 10px;
  margin-right: -97px;
}

.fleet_health_report_div_button-submit {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  //  margin-top: 18px;
  margin-left: 25px;
  float: right;
}
.fleet-health-details-search {
  width: 400px;
  height: 30px;
  margin-left: 13px;
  border-radius: 5px;
  border: 1px solid gray;
}
.Fleet-health-report_download {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.Fleet-health-report_download-img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}

.fleet-hover-1:hover {
  background-image: linear-gradient(to bottom, rgb(127, 244, 131), white);
}

.fleet-hover-2:hover {
  background-image: linear-gradient(to bottom, rgb(228, 127, 127), white);
}
.fleet-hover-3:hover {
  background-image: linear-gradient(to bottom, rgb(225, 244, 127), white);
}
.fleet-hover-4:hover {
  background-image: linear-gradient(to bottom, rgb(160, 166, 161), white);
}

.card-clicked {
  border: 2.3px solid orange;
  box-sizing: border-box;
}
