@import "../../stylesheets/robotoFonts";

.breakdown__summary {
  margin-top: 1px;
  margin-left: 15px;

  /* UI Properties */
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}

.ReactTable .rt-thead .rt-resizable-header {
  background-color: #eaedf1;
  color: #000 !important;
}
.ReactTable {
  //  margin-top: 3%;
  margin-left: 30px;
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}


.labels{
  display: flex;
}
.labels_styles {
 // display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-left: 7px;
  margin-top: -5px;
  margin-right: -98px;
  //margin-top: -5px;
}
.labels_styles-1 {
  // display: flex;
   font-family: $font-name-Roboto-Medium;
   font-size: 15px;
   margin-left: 5px;
   margin-top: -5px;
   margin-right: -102px;
   //margin-top: -5px;
 }
 


.button-submit-kpis {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 25px;
 // margin-right: 10px;
}

.button-submit-kpis-1 {
  padding: 4px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 10px;
 // margin-right: 10px;
}



// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker__input-container input {
//   display: block;
//   width: 155px;
//   text-align: center;
//   //padding: 3px 3px;
//   // margin-right: 13px;
//   //margin-left: -5px;
// }

.Breakdown-Details-report {
  // margin-left: 53px;
  //margin-top: -5px;
  margin-bottom: 10px;
  font-family: $font-name-Roboto-Regular;
}
.Breakdown-Details-input {
  width: 400px;
 // margin-top: 6px;
  height: 30px;
  margin-left: 48%;
}

.braekdown-details-download {
  margin-top: 5px;
  margin-left: 210px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.braekdown-details-download-img {
  margin-left: 6px;
  margin-top: -1px;
  cursor: pointer;
}
