@import "../../stylesheets/robotoFonts";
.backtoscreen-text {
  text-align: center;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  font: normal normal medium 14px/16px Roboto;
  letter-spacing: 0px;
  color: #d8231d;
  text-transform: capitalize;
  opacity: 1;
  cursor: pointer;
}
