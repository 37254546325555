@import "../../stylesheets/robotoFonts";

.rectangle {
  display: flex;
  margin-left: -250px;
  align-items: center;
  margin-top: 14px;
  top: 25px;
  left: 474px;
  width: 492px;
  height: 40px;
  /* UI Properties */
  background: transparent
    url("../../assets/images/Rectangle 847/Rectangle 847.png") 0% 0% no-repeat
    padding-box;
  border-radius: 4px;
  opacity: 1;

  .search {
    top: 28px;
    left: 489px;
    width: 32px;
    height: 21px;
    margin-top: 4px;
    margin-left: 15px;
    /* UI Properties */
    background: transparent
      url("../../assets/images/Icon feather-search/Icon feather-search.png") 0%
      0% no-repeat padding-box;
    opacity: 1;
  }
  input {
    top: 28px;
    left: 400px;
    width: 121px;
    height: 15px;
    /* UI Properties */
    text-align: left;
    //font: normal normal normal 13px/15px Roboto;
    letter-spacing: 0px;
    font-family: $font-name-Roboto-Medium;
    font-weight: 400;
    font-size: 13px;
    font-variant: normal;
    color: #9b9a9a;
    opacity: 0.5;
    outline: none;
    margin-left: -6px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
  }
  .filter {
    top: 29px;
    left: 937px;
    width: 15px;
    height: 13px;
    //align-items: flex-end;
    margin-left: 287px;
    /* UI Properties */
    background: transparent
      url("../../assets//images/Icon feather-filter/Icon feather-filter.png") 0%
      0% no-repeat padding-box;
    opacity: 1;
  }
}
