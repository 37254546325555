@import "../../stylesheets/robotoFonts";
.wdh-20 {
  width: 18%;
}
.data_change_head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 50px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.data_change_head_text {
  //margin-left: 10px;
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #527e7d;
  text-transform: uppercase;
  opacity: 1;
}

.data_change_body_1 {
  color: #527e7d;
  font-family: $font-name-Roboto-Bold;
  font-size: 14px;
  //text-align: center;
  margin-left: 20px;
  margin-top: 25px;
}

.data_change_body_2 {
  color: #527e7d;
  font-family: $font-name-Roboto-Bold;
  font-size: 14px;
  //text-align: center;
  margin-left: 20px;
  margin-top: -10px;
}
.data_change_button{
padding: 5px 5px;
background-color: #ed2541;
border: none;
outline: none;
border-radius: 3px;
font-size: 14px;
color: #fff;
font-family: $font-name-Roboto-Bold;
cursor: pointer;
width: 120px;
font-weight: 500;
letter-spacing: 1px;
align-items: center;
text-align: center;
margin-left: 28%;
margin-bottom: 30px;
}