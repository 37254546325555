@import "../../stylesheets/robotoFonts";

.checklistsummary {
  margin-top: 1px;
  margin-left: 15px;
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 22px;
  font-weight: 700;
  font-variant: normal;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}
.ReactTable .rt-thead .rt-resizable-header {
  background-color: #0089d3;
  color: "white" !important;
}
.ReactTable {
  margin-top: 3%;
  margin-left: 30px;
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}

.driver-feedback_labels {
  display: flex;
}
.driver-feedback_labels_styles {
  // display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-left: 7px;
  margin-top: -5px;
  margin-right: -98px;
}

.driver-feedback_labels_styles-1 {
  // display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-left: 5px;
  margin-top: -5px;
  margin-right: -102px;
}
// .labels_styles-1 {
//   display: flex;
//   font-family: $font-name-Roboto-Medium;
//   font-size: 15px;
//   // margin-right: 15px;
//   margin-left: 10px;
//   margin-top: -5px;
// }

.driver-feedback-button-submit-kpis {
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 25px;
  // margin-right: 10px;
}

.driver-feedback-button-submit-kpis-1 {
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
  // margin-right: 10px;
}

// .react-datepicker-wrapper,
// .react-datepicker__input-container,
// .react-datepicker__input-container input {
//   display: block;
//   width: 155px;
//   text-align: center;
//   //padding: 3px 3px;
//   // margin-right: 13px;
//   //margin-left: -5px;
// }

.driver-feedback-report {
  // margin-left: 53px;
  //margin-top: -5px;
  margin-bottom: 10px;
  font-family: $font-name-Roboto-Regular;
}
.driver-feedback-input {
  width: 400px;
  // margin-top: 6px;
  height: 30px;
  margin-left: 48%;
}

.driver-feedback-download {
  margin-top: 5px;
  margin-left: 210px;
  font-size: 14px;
  letter-spacing: 0px;
  cursor: pointer;
  color: #8997b5;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}

.driver-feedback-download-img {
  margin-left: 10px;
  margin-top: -1px;
  cursor: pointer;
}
