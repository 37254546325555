@import "../../stylesheets/robotoFonts";

.compliance_kpi_text {
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -25px;
  margin-left: 40px;
  text-align: left;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.compliance_kpi_image {
  width: 15px;
  margin-top: -10px;
  margin-left: 20px;
}

.compliance_kpi_footer {
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
}

.compliance_kpi_text-1 {
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
  color: #6e6e6e;
}

.bg-gray {
  background: #dbdee8 0% 0% no-repeat padding-box;
}

.compliance_kpi_text-2 {
  //margin-top: -1px;
  text-align: center;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #ed2541;
  text-transform: uppercase;
  opacity: 1;
}

.compliance_kpi_head-text {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.width-20 {
  width: 23%;
}

.compliance_kpi_head_img-1 {
  margin-top: -52px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.compliance_kpi_head_img {
  margin-top: -5px;
  margin-right: 10px;
  margin-left: -5px;
  //width: 18px;
}

.compliance_kpi_head-text-1 {
  margin-left: 40px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

.compliance_kpi_head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.lotty-player-compliance {
  margin-left: 130px;
  margin-top: 30px;
  height: 50px;
  width: 50px;
}
