@import "../../stylesheets/robotoFonts";

.calanderListview-search-input {
  width: 400px;
  // margin-top: 6px;
  height: 30px;
  // margin-left: 237px;
  font-family: $font-name-Roboto-Regular;
}
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  background-color: #eaedf1;
  mix-blend-mode: normal;
  text-align: center;
  opacity: 1;
  color: #000;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  font-family: $font-name-Roboto-Medium;
}

.driver-monitoring-driver {
  text-align: left;
  letter-spacing: 0.28px;
  color: #353843;
  opacity: 1;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  //margin-left: 43px;
}

.driver-monitoring_div_button-submits {
  margin-left: 20px;
  margin-top: 5px;
  font-size: 14px;
  font-family: $font-name-Roboto-Regular;
  cursor: pointer;
  color: #8997b5;
}

.driver-monitoring_div_button-submit-image {
  margin-top: 0px;
  margin-left: 7px;
}
