@import "../../stylesheets/robotoFonts";



.unplaned_stop {
  display: flex;
}

.unplaned_stop_one {
  margin-left: 30px;
  cursor: pointer;

}
.double-image-two{
  cursor: pointer;
}

.unplaned_stop_image_one{
    width: 50px;
    margin-top: 30px;
}

.unplaned_stop_image_two{
    width: 50px;
    margin-top: 30px;
    margin-left: 10px;
}

.unplaned-text-one {
  font-size: 12px;
  //margin-top: 5px;
  font-family: $font-name-Roboto-Bold;
  text-align: center;
  letter-spacing: 0.24px;
  color: #6E6E6E;
  text-transform: uppercase;
  opacity: 1;
  margin-left: -10px;
}
.unplaned-text-one-1 {
    font-size: 12px;
  //margin-top: 6px;
    font-family: $font-name-Roboto-Bold;
    text-align: center;
    letter-spacing: 0.24px;
    color: #6E6E6E;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 10px;
  }

.unplaned-number-one {
  margin-top: 14px;
  margin-left: 17px;
  text-align: left;
  font-size: 15px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.3px;
  color: #343844;
  opacity: 1;
}




.unplaned-number-one-1 {
  margin-top: 24px;
  margin-left: 30px;
  text-align: left;
  font-size: 15px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.3px;
  color: #343844;
  opacity: 1;
}

.unplaned_perpendiculars-line {
    width: 23%;
    margin-top: 29%;
    margin-bottom: 0;
    transform: rotate(90deg);
    border-color: #DBDEE8;
  }
