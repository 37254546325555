.react-select {
    text-align: left;
    //font: normal normal bold 12px/14px Roboto;
    //font-family: $font-name-Roboto-Regular;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.24px;
    color: #353843;
    font-size: 10px;
    opacity: 1;
  }
