 @import "../../stylesheets/robotoFonts";



.perday-content {
  display: flex;
}
.perday-image {
  margin-left: 20px;
  width: 40px;
  height: 60px;
  margin-top: 30px;
}

.perday-number {
  margin-top: 30px;
  margin-left: 35px;
  text-align: left;
  font-size: 16px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #353639;
  opacity: 1;
}

.perday-text {
  margin-top: -7px;
  text-align: left;
  margin-left: 35px;
  font-family: $font-name-Roboto-Bold;
  font-size: 13px;
  letter-spacing: 0.28px;
color: #19387D;
text-transform: uppercase;
opacity: 1;
}

.perday-hr {
  color: gray;
  margin-top: -5px;
}

.perday-double-image {
  display: flex;
}

.double-image-one {
  display: flex;
  margin-left: 30px;
}

// .perday-image-one {
//   width: 40px;
// }

// .double-image-two {
//   display: flex;
//   margin-left: 150px;
// }

.perday-text-one {
  font-size: 12px;
  margin-top: -1px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.28px;
  color: #19387D;
  text-transform: uppercase;
  opacity: 1;
  margin-left: -10px;
}

.perday-number-one {
  margin-top: -10px;
  margin-left: -10px;
  text-align: left;
  font-size: 15px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.3px;
  color: #343844;
  opacity: 1;
}


.perday-text-one-1 {
  font-size: 12px;
margin-top: -1px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.28px;
  color: #19387D;
  text-transform: uppercase;
  opacity: 1;
  margin-left: 20px;
}

.perday-number-one-1 {
  margin-top: -10px;
  margin-left: 20px;
  text-align: left;
  font-size: 15px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0.3px;
  color: #343844;
  opacity: 1;
}

.perpendiculars-line {
    width: 23%;
    margin-left: -12px;
    //margin: auto;
    margin-top: 12%;
    margin-bottom: 0;
    transform: rotate(90deg);
    border-color: #1A85FD;
  }
