@import "../../stylesheets/robotoFonts";
.Track-page-heading {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
  // margin-top: 1px;
  margin-left: 15px;
  //margin-top: 15px;
}
.track-backward {
  display: flex;
}
.switch-field {
  font-family: $font-name-Roboto-Regular;
  display: flex;
  margin-top: -20px;
  margin-left: 285px;
}
.switch-img {
  margin-left: -10px;
}
.switch-img-1 {
  margin-right: 4px;
}
.switch-title {
  font-weight: bold;
  margin-bottom: 6px;
}
.switch-field input {
  position: absolute !important;
  clip: rect(0, 0, 0, 0);
  //height: 1px;
  //width: 1px;
  border: 0;
  overflow: hidden;
}
.switch-field label {
  display: inline-block;
  width: 13vh;
  background-color: white;
  color: rgba(0, 0, 0, 0.6);
  font-size: 80%;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  padding: 10px 14px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
  transition: all 0.1s ease-in-out;
}
.switch-field label:hover {
  cursor: pointer;
}
.switch-field input:checked + label {
  background-color: #18397d;
  color: #fff;
  box-shadow: none;
}
.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
}
.switch-field label:last-of-type {
  border-radius: 0 4px 4px 0;
}
.w-47 {
  width: 48%;
}
.mg-left {
  margin-left: -72px;
}
// .mapactionone-body {
//   margin-top: 20px;
//   margin-left: 20px;
// }
.mapactionone-body-1 {
  margin-top: 10px;
  margin-left: 20px;
}
.track-hr {
  color: #7668684f;
  margin-top: -10px;
}
