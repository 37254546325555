@import "../../stylesheets/robotoFonts";

// .driver-update {
//   margin-top: 20px;
//   margin-left: 35px;

//   /* UI Properties */
//   text-align: left;
//   font-family: $font-name-Roboto-Bold;
//   font-size: 22px;
//   font-weight: 700;
//   font-variant: normal;
//   letter-spacing: 0px;
//   color: #073a81;
//   text-transform: capitalize;
//   opacity: 1;
// }

// .breakdownsummary {
//   margin-top: 20px;
//   margin-left: 50px;

//   /* UI Properties */
//   text-align: left;
//   font-family: $font-name-Roboto-Bold;
//   font-size: 22px;
//   font-weight: 700;
//   font-variant: normal;
//   letter-spacing: 0px;
//   color: #073a81;
//   text-transform: capitalize;
//   opacity: 1;
// }
.ReactTable .rt-thead .rt-resizable-header {
  background-color: #eaedf1;
  color: #000 !important;
}
.rt-td {
  text-align: center;
  font-size: 13px;
  font-family: $font-name-Roboto-Regular;
}
.rt-resizable-header-content {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.ReactTable .-pagination .-btn {
  font-size: 1em;
}

// .driver-update-subhead {
//   display: flex;
//   //margin-left: 3%;
//   //padding-right: 20px;
//   //margin-top: -15px;
//   //margin-bottom: 10px;
// }



.search-driver-update-report {
  margin-left: 30px;
  margin-bottom: 10px;
  //margin-top: 30px;
  font-family: $font-name-Roboto-Regular;
}

.driver-update-subhead{
  display: flex;
}

.search-driver-update-input {
  width: 400px;
  height: 30px;
  //margin-left: 15px;
  margin-top: -30px;
}



.records-info{
  margin-left: 40px;
 // margin-top: -20px;
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
}

.driver-master-Report-download{
  margin-top: -25px;
  margin-left: 105%;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}

.driver-master-Report-download-img{
 
  margin-left: 47px;
 cursor: pointer;
 margin-top: -43px;
}


.master-driver-loader-overlay {
  position: fixed;
  top: 65%;
  right: 0;
  bottom: 0;
  left: 45%;
  font-size: 15px;
  // background: rgba(0, 0, 0, 0.2);
  // z-index: 1;
}

.master-driverloader-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.alert {
  padding: 12px 20px;
  font-size: 16px;
  position: absolute;
  color: white;
  font-family: $font-name-Roboto-Regular;
  left: 3;
  right: 0;
  top: 0;
  border-right: 10px solid #fff;
}

.error {
  background-color: #f44336;
}

.success {
  background-color: #4caf50;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}