@import "../../stylesheets/robotoFonts";

.container-4 {
  margin-top: 63px;
  margin-left: 40px;
  margin-right: 40px;

  .col-sm {
    margin-top: -46px;
    //left: 140px;
    width: 25%;
    height: 156px;
    margin-left: 11px;
    background: transparent linear-gradient(180deg, #073a81 0%, #041d41 100%) 0%
      0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #000000bf;
    border-radius: 10px;
    opacity: 1;
  }

  .left {
    .number-pulse {
      display: flex;
      .pulsatingDotone {
        margin-top: 85px;
        margin-left: 15px;
        animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        background-color: var(--pulsating-dot, #c0e594);
        border-radius: 50%;
        box-sizing: border-box;
        height: 10px;
        width: 10px;
      }

      .pulsatingDotone:before {
        animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        background-color: var(--pulsating-dot, #c0e594);
        border-radius: 45px;
        content: "";
        display: block;
        height: 300%;
        left: -100%;
        position: relative;
        top: -100%;
        width: 300%;
      }

      @keyframes pulseRing {
        0% {
          transform: scale(0.5);
        }
        80%,
        100% {
          opacity: 0;
        }
      }

      @keyframes pulseDot {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
      .number-1 {
        // top: 721px;
        // left: 88px;
        margin-left: 15px;
        width: 165px;
        height: 67px;
        /* UI Properties */
        text-align: left;
        font-size: 37px;
        //font-size: medium;
        font-family: $font-name-Roboto-Regular;
        //  font-variant: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #c0e594;
        text-transform: uppercase;
        opacity: 1;
        margin-top: 12px;
        padding-top: 3rem;
      }
    }

    .number-pulse {
      display: flex;
      .pulsatingDottwo {
        margin-top: 85px;
        margin-left: 15px;
        animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        background-color: var(--pulsating-dot, #f5c06b);
        border-radius: 50%;
        box-sizing: border-box;
        height: 10px;
        width: 10px;
      }

      .pulsatingDottwo:before {
        animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        background-color: var(--pulsating-dot, #f5c06b);
        border-radius: 45px;
        content: "";
        display: block;
        height: 300%;
        left: -100%;
        position: relative;
        top: -100%;
        width: 300%;
      }

      @keyframes pulseRing {
        0% {
          transform: scale(0.5);
        }
        80%,
        100% {
          opacity: 0;
        }
      }

      @keyframes pulseDot {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
      .number-2 {
        // top: 721px;
        // left: 82px;
        margin-left: 15px;
        width: 65px;
        height: 67px;
        /* UI Properties */
        text-align: left;
        font-size: 37px;
        font-family: $font-name-Roboto-Regular;
        //    font-variant: normal;
        font-weight: 400;
        font-style: normal;
        margin-top: 12px;
        letter-spacing: 0px;
        color: #f5c06b;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 3rem;
      }
    }

    .number-pulse {
      display: flex;
      .pulsatingDotthree {
        margin-top: 85px;
        margin-left: 15px;
        animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        background-color: var(--pulsating-dot, #ec9b98);
        border-radius: 50%;
        box-sizing: border-box;
        height: 10px;
        width: 10px;
      }

      .pulsatingDotthree:before {
        animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        background-color: var(--pulsating-dot, #ec9b98);
        border-radius: 45px;
        content: "";
        display: block;
        height: 300%;
        left: -100%;
        position: relative;
        top: -100%;
        width: 300%;
      }

      @keyframes pulseRing {
        0% {
          transform: scale(0.5);
        }
        80%,
        100% {
          opacity: 0;
        }
      }

      @keyframes pulseDot {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
      .number-3 {
        // top: 721px;
        // left: 82px;
        margin-left: 15px;
        width: 65px;
        height: 67px;
        /* UI Properties */
        text-align: left;
        font-size: 37px;
        margin-top: 12px;
        font-family: $font-name-Roboto-Regular;
        //   font-variant: normal;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 0px;
        color: #ec9b98;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 3rem;
      }
    }

    .number-pulse {
      display: flex;
      .pulsatingDotfour {
        margin-top: 85px;
        margin-left: 15px;
        animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        background-color: var(--pulsating-dot, #fdf4a0);
        border-radius: 50%;
        box-sizing: border-box;
        height: 10px;
        width: 10px;
      }

      .pulsatingDotfour:before {
        animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        background-color: var(--pulsating-dot, #fdf4a0);
        border-radius: 45px;
        content: "";
        display: block;
        height: 300%;
        left: -100%;
        position: relative;
        top: -100%;
        width: 300%;
      }

      @keyframes pulseRing {
        0% {
          transform: scale(0.5);
        }
        80%,
        100% {
          opacity: 0;
        }
      }

      @keyframes pulseDot {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
      .number-4 {
        // top: 721px;
        // left: 82px;
        margin-left: 15px;
        width: 65px;
        height: 67px;
        /* UI Properties */
        text-align: left;
        font-size: 37px;
        font-family: $font-name-Roboto-Regular;
        //   font-variant: normal;
        font-weight: 400;
        margin-top: 12px;
        font-style: normal;
        letter-spacing: 0px;
        color: #fdf4a0;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 3rem;

        //margin-bottom: 20px;
      }
    }

    .number-pulse {
      display: flex;
      .pulsatingDotfive {
        margin-top: 85px;
        margin-left: 15px;
        animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
        background-color: var(--pulsating-dot, cornflowerblue);
        border-radius: 50%;
        box-sizing: border-box;
        height: 10px;
        width: 10px;
      }

      .pulsatingDotfive:before {
        animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
        background-color: var(--pulsating-dot, cornflowerblue);
        border-radius: 45px;
        content: "";
        display: block;
        height: 300%;
        left: -100%;
        position: relative;
        top: -100%;
        width: 300%;
      }

      @keyframes pulseRing {
        0% {
          transform: scale(0.5);
        }
        80%,
        100% {
          opacity: 0;
        }
      }

      @keyframes pulseDot {
        0%,
        100% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.1);
        }
      }
      .number-5 {
        // top: 721px;
        // left: 82px;
        margin-left: 15px;
        width: 65px;
        height: 67px;
        /* UI Properties */
        text-align: left;
        font-size: 37px;
        font-family: $font-name-Roboto-Regular;
        //   font-variant: normal;
        font-weight: 400;
        margin-top: 12px;
        font-style: normal;
        letter-spacing: 0px;
        color: cornflowerblue;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 3rem;

        //margin-bottom: 20px;
      }
    }

    .text-1 {
      //top: 568px;
      //left: 52px;
      //width: 144px;
      height: 16px;
      /* UI Properties */
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.1px;
      font-family: $font-name-Roboto-Regular;
      //   font-variant: normal;
      font-weight: 500;
      font-style: normal;
      //letter-spacing: 0.28px;
      color: #c0e594;
      text-transform: uppercase;
      opacity: 1;
      margin-top: -10px;
    }
    .text-2 {
      //top: 568px;
      //left: 52px;
      //width: 144px;
      height: 16px;
      /* UI Properties */
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.1px;
      font-family: $font-name-Roboto-Regular;
      //   font-variant: normal;
      font-weight: 500;
      font-style: normal;
      //letter-spacing: 0.28px;
      color: #f5c06b;
      text-transform: uppercase;
      opacity: 1;
      margin-top: -10px;
    }
    .text-3 {
      //top: 568px;
      //left: 52px;
      //width: 144px;
      height: 16px;
      /* UI Properties */
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.1px;
      font-family: $font-name-Roboto-Regular;
      //   font-variant: normal;
      font-weight: 500;
      font-style: normal;
      //letter-spacing: 0.28px;
      color: #ec9b98;
      text-transform: uppercase;
      opacity: 1;
      margin-top: -10px;
    }
    .text-4 {
      //top: 568px;
      //left: 52px;
      //width: 144px;
      height: 16px;
      /* UI Properties */
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.1px;
      font-family: $font-name-Roboto-Regular;
      //   font-variant: normal;
      font-weight: 500;
      font-style: normal;
      //letter-spacing: 0.28px;
      color: #fdf4a0;
      text-transform: uppercase;
      opacity: 1;
      margin-top: -10px;
    }
    .text-5 {
      //top: 568px;
      //left: 52px;
      //width: 144px;
      height: 16px;
      /* UI Properties */
      text-align: left;
      font-size: 12px;
      letter-spacing: 1.1px;
      font-family: $font-name-Roboto-Regular;
      //   font-variant: normal;
      font-weight: 500;
      font-style: normal;
      //letter-spacing: 0.28px;
      color: cornflowerblue;
      text-transform: uppercase;
      opacity: 1;
      margin-top: -10px;
    }
  }

  .text_alignment {
    padding-top: 1.7rem;
  }

  .right {
    // padding-left: 70%;
    // top: 335px;
    margin-top: -25px;
    //left: 20px;
    margin-left: 70%;
    // width: 85px;
    //height: 46px;
    //padding-top: -0.5rem;
  }

  .right_occupies_station {
    padding-left: 68%;
    margin-top: -25px;
    // padding-top: -10rem;
  }

  .right_breakdown_station {
    padding-left: 80%;
    // padding-top: 2rem;
    // margin-top: 1px;
    margin-top: -28px;
  }

  .right_charger_efficiency {
    padding-left: 87%;
    //padding-top: 2rem;
    margin-top: -28px;
  }
  .oppertunity_charger {
    padding-left: 85%;
    padding-top: 2rem;
  }
}
.charger_top_elements {
  display: flex;
  margin-top: -115px;

  .chargers_head_img {
    //margin-top: -190px;
    color: white;
    //margin-left: 5px;
  }

  .chargers_button {
    margin-left: -10px;
    //padding-left: 30%;
    //margin-top: -80px;
    .lp_review_err_btn {
      //background-color: #0086d0;
      height: 25px;
      width: 70%;
      /* padding-left: 14px; */
      background-color: #073a81;
      border: 1px solid white;
      cursor: pointer;
      border-radius: 20px;
      font-size: 9px;
      font-weight: 500;
      font-feature-settings: normal;
      font-variant: normal;
      text-align: center;
      font-family: "Roboto-Regular";
      letter-spacing: 1px;
      color: #ffffff;
      text-transform: uppercase;
      opacity: 1;
      margin-left: 48px;
      margin-top: 7px;
    }
    .lp_next_png {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
      //margin-top: 5px;
      //margin-top: 4px;
      //padding-right: 5px;
    }
  }
}

/* Parent Container */
.charger_content_img {
  position: relative;
  width: 200px;
  height: 200px;
  float: left;
  //margin-right: 10px;
}

/* Child Text Container */
.charger_content_img div {
  position: absolute;
  bottom: 10;
  right: 0;
  top: 0px;
  left: 19px;
  height: 49px;
  width: 160px;
  //width: 200px;
  background: rgb(232, 232, 238);
  color: rgb(6, 9, 84);
  margin-bottom: 5px;
  font-family: $font-name-Roboto-Regular;
  opacity: 0;
  font-size: 12px;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.charger_content_img:hover {
  cursor: pointer;
}

.charger_content_img:hover div {
  width: 160px;
  padding: 4px 3px;
  visibility: visible;
  opacity: 0.8;
}
