@import "../../stylesheets/robotoFonts";
.vehicle-effciency {
  display: flex;
  gap: 30px;
}

.vehicle-effciency-wash {
  display: flex;
  margin-left: 10px;
}

.vehicle-effciency-charge {
  display: flex;
  margin-left: -30px;
}

.vehicle-effciency-img-1 {
  width: 47px;
  height: 31px;
  margin-top: 20px;
}

.vehicle-effciency-img-2 {
  width: 47px;
  height: 31px;
  margin-top: 20px;
}

.vehicle-effciency-text {
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Medium;
  color: #353639;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  margin-top: 18px;
  margin-left: 9px;
}
.vehicle-effciency-text-1{
  font-family: $font-name-Roboto-Medium;
  color: #353639;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  margin-top: 18px;
  margin-left: 5px;
  margin-right: -42px;
}

.vehicle-effciency-number-1 {
  font-size: 24px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #e63e33;
  opacity: 1;
  margin-top: 18px;
  margin-left: 3px;
}

.vehicle-effciency-number-2 {
  font-size: 24px;
  font-family: $font-name-Roboto-Bold;
  margin-top: 18px;
  margin-left: 3px;
  letter-spacing: 0px;
  color: #6bb13f;
  opacity: 1;
}
.effciency-hr {
  margin-top: -10px;
  color: gray;
}

.dot-image {
  margin-left: 20px;
}

.button-text {
  font-size: 14px;
  font-family: $font-name-Roboto-Medium;
  margin-left: 3px;
  letter-spacing: 0px;
  color: #062859;
  opacity: 1;
}

.button-text-1 {
  font-size: 15px;
  font-family: $font-name-Roboto-Medium;
  margin-left: 3px;
  letter-spacing: 0px;
  color: #062859;
  opacity: 1;
}

.effciencys-line {
  width: 100%;
  height: 80%;
  margin-left: -190px;
  transform: rotate(90deg);
  border-color: gray;
}

.effciency-rightside {
  display: flex;
  margin-top: -20px;
}

.border-on-right {
  border-right: 1px solid #dbdee8;
  margin-left: 30px;
}

.vehicle-effciency-hr {
  margin-top: -5px;
  border: 1px solid #dbdee8;
}