@import "../../stylesheets/robotoFonts";
.summary-table {
  margin-left: 20px;
  .ReactTable .rt-thead .rt-th.-cursor-pointer {
    background-color: #eaedf1;
    padding: 9px;
    mix-blend-mode: normal;
    text-align: center;
    opacity: 1;
    color: #2d2357;
    font-size: 14px;
    font-weight: 400;
    font-family: $font-name-Roboto-Medium;
  }
  .ReactTable .rt-tbody {
    text-align: center;
    min-width: 100px;

    display: flex;
    flex-direction: column;
    // overflow-x: none;
  }
  .ReactTable .rt-table {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    // overflow-x:;
  }
}

.onclick-action {
  color: #da3d48;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Bold;
  text-decoration: none;
}
.onclick-action:hover {
  color: red;
}
.search-summary-report {
  display: flex;
}
.search-summary-report-input{
  //margin-top: 15px;
  margin-left: 10px;
  margin-bottom: 10px;
  width: 400px;
  height: 30px;
  font-family: $font-name-Roboto-Regular;
}

.summary_report-download {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  opacity: 1;
  cursor: pointer;
  font-family: $font-name-Roboto-Medium;
}
.summary_report-download-img {
  margin-left: 7px;
  
  cursor: pointer;
  margin-top: -1px;
}