@import "../../stylesheets/robotoFonts";
.driver-details-action {
  color: #da3d48;
  text-transform: uppercase;
  opacity: 1;
  border: white;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Bold;
  //text-decoration: none;
  background-color: rgb(244, 234, 234);
  margin-right: 5px;
}

.driverdetails-action-img {
  margin-left: 5px;
}
