
@import "../../stylesheets/robotoFonts";
.driver-details-div-first-img {
  .driver-details-first-img {
    margin-top: 10px;
    margin-left: 40px;
    // position: absolute;
    width: 455px;
    height: 99px;
  }
  .DD-driver-image {
    //margin-top: -7px;
    margin-top: -20px;
    margin-left: -435px;
    width: 18px;
  }
  .driver-details-first-image-no {
    font-size: 19px;
    color: white;
    margin-top: -59px;
    margin-left: 55px;
  }

  .drivers {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 11px;
    font-family: $font-name-Roboto-Medium;
    margin-left: 24px;
    margin-top: -5px;
  }

  .driver-details-img-data-green {
    color: white;
    font-size: 20px;
    margin-top: -110px;
    margin-left: 143px;
  }

  .driver-details-img-data-red {
    color: white;
    font-size: 20px;
    margin-top: 33px;
    margin-left: 143px;
  }
  .reported {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 11px;
    font-family: $font-name-Roboto-Medium;
    margin-left: 260px;
    position: relative;
    margin-top: -39px;
  }
  .daily-check-done {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 11px;
    font-family: $font-name-Roboto-Medium;
    margin-left: 260px;
    position: relative;
    margin-top: -39px;
  }
  .green-dot-driver-details {
    margin-left: 530px;
    margin-top: -38px;
  }
  .driver-detils-dot-green-img {
    margin-left: 18px;
  }
  .driver-detils-dot-red-img {
    margin-left: 17px;
    width: 12px;
  }
  .driver_details_textss {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 12px;
    margin-left: 5px;
    font-family: $font-name-Roboto-Medium;
  }
}

.driver-details-div-second-img {
  position: absolute;

  .driver_details_second_img {
    margin-top: 30px;
    margin-left: 40px;
    position: absolute;
    width: 455px;
    height: 99px;
  }

  .drivers-1 {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 11px;
    font-family: $font-name-Roboto-Medium;
    margin-left: 30px;
    margin-top: 10px;
  }

  .driver-details-img-data-two-image-no {
    color: white;
    font-size: 18px;
    // margin-top: 30px;
    position: relative;
    margin-left: 57px;
  }

  .DD-bus-image {
    margin-top: 50px;
    margin-left: 60px;
    position: relative;
    width: 19px;
  }
  .started {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 11px;
    font-family: $font-name-Roboto-Medium;
    margin-left: 260px;
    position: relative;
    margin-top: -42px;
    // padding-bottom: 4px;
  }
  .completed {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 11px;
    font-family: $font-name-Roboto-Medium;
    margin-left: 260px;
    position: relative;
    margin-top: -38px;
    //padding-top: 5px;
  }

  .driver-details-img-2-blue {
    color: white;
    font-size: 20px;
    margin-top: -110px;
    margin-left: 137px;
    position: relative;
  }

  .driver-details-img-3-blue {
    color: white;
    font-size: 20px;
    margin-top: -110px;
    margin-left: 148px;
    position: relative;
  }

  .driver-details-img-2-orange {
    color: white;
    font-size: 20px;
    margin-top: -10px;
    margin-left: 137px;
    position: relative;
  }

  .driver-details-img-3-orange {
    color: white;
    font-size: 20px;
    margin-top: -10px;
    margin-left: 138px;
    position: relative;
  }

  .driver-details-img-2-orange-data {
    margin-top: 50px;
  }
  .driver_details_textss {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 14px;
    margin-left: 5px;
    font-family: $font-name-Roboto-Medium;
  }

  .green-dot-driver-details-1 {
    margin-left: 500px;
    margin-top: -30px;
  }

  .green-dot-driver-details-orange {
    margin-left: 500px;
    margin-top: -40px;
  }

  .green-dot-driver-details-blue {
    margin-left: 548px;
    margin-top: -40px;
  }

  .green-dot-driver-details-orange {
    margin-left: 548px;
    margin-top: -40px;
  }
  .driver_details_textss-1 {
    letter-spacing: 0px;
    color: #041f46;
    opacity: 1;
    font-size: 12px;
    margin-left: 5px;
    font-family: $font-name-Roboto-Medium;
  }
}
