@import "../../stylesheets/robotoFonts";
.Trip_Monitoring-text{
    margin-top: 1px;
    margin-left: 15px;
  
    /* UI Properties */
    text-align: left;
    font-family: $font-name-Roboto-Bold;
    font-size: 22px;
    font-weight: 700;
    font-variant: normal;
    letter-spacing: 0px;
    color: #073a81;
    text-transform: capitalize;
    opacity: 1;
}
.TripMonitoring-action{
  color: #da3d48;
  text-transform: uppercase;
  opacity: 1;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: $font-name-Roboto-Bold;
  text-decoration: none;
}
.ReactTable .rt-thead .rt-resizable-header {
    background-color: #eaedf1;
    color: #000 !important;
  }
  .rt-td {
    text-align: center;
    font-size: 13px;
    font-family: $font-name-Roboto-Regular;
  }
  .rt-resizable-header-content {
    font-size: 14px;
    font-family: $font-name-Roboto-Medium;
  }
  
  .ReactTable .-pagination .-btn {
    font-size: 1em;
  }
  .ReactTable .rt-tbody {
    overflow-x: hidden;
  }
  .TripMonitoring-search{
    width: 400px;
    height: 35px;
    margin-left: 50px;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid gray;
}
.trip_monitoring_report_div {
  
  margin-top: -25px;
  display: flex;
  padding: 2rem;
}
.trip_monitoring_report_div_labels {
  display: flex;
}
.trip_monitoring_report_div_labels_style-1 {
display: flex;
font-family: $font-name-Roboto-Medium;
font-size: 15px;
margin-top: -5px;
margin-left: 10px;
margin-right: -97px;
}

.trip_monitoring_report_div_labels_style{
  display: flex;
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  margin-top: -5px;
  margin-left: 27px;
  margin-right: -97px;
  }
.trip_monitoring_report_div_button-submit {
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  // margin-top: 1px;
 margin-left: 25px;
  float: right;
}
.trip_monitoring_report_div_button-submit-1 {
  padding: 5px 5px;
  background-color: #ed2541;
  border: none;
  outline: none;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-left: 20px;
  // margin-top: 1px;
  //margin-left: -55px;
  //margin-right: 10px;
  float: right;
}
.trip_monitoring_report_search-input {
  width: 400px;
  margin-top: 1px;
  font-family: $font-name-Roboto-Regular;
  height: 30px;
  margin-left: 40px;
}
 ::placeholder {
   font-size: 13px;
   color: #acb0bc;
   padding-left: 12px;
   opacity: 1;
   letter-spacing: 0px;
 }
.trip_monitoring_report-download {
  margin-top: 5px;
  margin-left: 20px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #8997b5;
  cursor: pointer;
  opacity: 1;
  font-family: $font-name-Roboto-Medium;
}
.trip_monitoring_report-download-img {
  margin-left: 7px;
  margin-top: -1px;
  cursor: pointer;
}


 