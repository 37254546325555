@import "../../stylesheets/robotoFonts";

.fleet_health_detail_hr {
  border: 3px solid #383838;
}
.fleet-health-details-backward{
  display: flex;
  margin-top: 10px;
  margin-left: 1px;
}
.fleet-health-details-heading {
  text-align: left;
  font-size: 20px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
  // margin-top: 20px;
  margin-left: 15px;
  margin-top: 1px;
}

.fleet_health_detail_chassis {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #828ea7;
  opacity: 1;
}

.fleet_health_detail_chassis_no {
  text-align: left;
  font-size: 16px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #083a81;
  opacity: 1;
  margin-top: -10px;
}

.fleet_health_detail_reg {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #828ea7;
  opacity: 1;
  margin-left: 30px;
}

.fleet_health_detail_reg_no {
  text-align: left;
  font-size: 16px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #083a81;
  opacity: 1;
  margin-left: 30px;
  margin-top: -10px;
}

.fleet_details_text_1 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Medium;
  letter-spacing: 0px;
  color: #363845;
  opacity: 1;
}

.fleet_details_text_2 {
  text-align: left;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #363845;
  opacity: 1;
  margin-top: 10px;
}

.bg-blue {
  background-color: #e8eced;
}

.fleet_details_hr-1 {
  border: 1px solid #f8ecec;
}
