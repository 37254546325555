
  @import "../../stylesheets/robotoFonts";
.fleet-uptime-table {
    &__layout {
      height: calc(100vh - 2.85rem - 5rem - 1.71rem - 1.71rem - 1.71rem - 1.9rem - 3rem);
      background-color: transparent;
      display: flex;
      flex-wrap: wrap;
      padding: 0 1.71rem;
      &__col-1 {
        width: 100%;
        height: 100%;
        &__block {
          height: 100%;
          padding-right: 1.71rem;
          &__wrapper {
            height: 100%;
            width: 100%;
            background-color: white;
            border-radius: 10px;
            &__header {
             // margin-top: -10px;
              height: 4rem;
              display: flex;
              justify-content: space-between;
              position: relative;
              &__left {
                display: flex;
                flex-direction: row;
                &__tab {
                  position: relative;
                  &:after {
                    content: " ";
                    display: table;
                    clear: both;
                  }
                  margin: 0;
                  padding: 0;
                  list-style: none;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  cursor: pointer;
                  font-family: $font-name-Roboto-Bold;
                  text-align: center;
                  margin-left: 2.286rem;
                  position: relative;
                  overflow: hidden;
                  &:first-child {
                    margin-left: 1.014rem;
                  }
                  > a {
                    align-self: center;
                    display: block;
                    text-decoration: none;
                    color: black;
                    transition: color 0.15s;
                    font-size: 14px;
                    pointer-events: none;
                    opacity: 0.3;
                  }
                  &.active {
                    > a {
                      // color: $color-blue-primary1;
                      // color: #222222;
                      opacity: 1;
                      font-family: $font-name-Roboto-Bold;
                      font-size: 14px;
                      color: #083A81;
                    }
                    .tab-strips-1 {
                      opacity: 1;
                    }
                  }
                  &:hover {
                    > a {
                      opacity: 1;
                      // color: #222222;
                      font-family: $font-name-Roboto-Bold;
                      font-size: 14px;
                      color: #083A81;
                      // color: $color-blue-primary1;
                    }
                    .tab-strips-1 {
                      opacity: 1;
                      -webkit-animation-name: horizontal-strip-move-animation;
                      -webkit-animation-duration: 2s;
                      animation-name: horizontal-strip-move-animation;
                      animation-duration: 0.2s;
                    }
                  }
                }
                .tab-strips-1 {
                  width: 100%;
                  height: 0.214rem;
                  background-color: #083A81;
                  opacity: 0;
                  position: absolute;
                  bottom: 17px;
                }
              }
              &__right {
                display: flex;
                align-items: center;
                margin-right: 1.7143rem;
              }
            }
            &__horizontal-line {
              height: 0.2143rem;
              opacity: 0.2;
              margin-top: -20px;
              //color: $color-warm-grey-two;
            }
            &__content {
              width: 100%;
              height: 75%;
              //overflow: scroll;
              margin-top: 1.357rem;
            }
          }
        }
      }

    }
  
  

  }
  

 