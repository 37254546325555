@import "../../stylesheets/robotoFonts";



.ReactTable .rt-thead {
  background-color: #eaedf1;
  color: #000 !important;
  font-family: $font-name-Roboto-Medium;
  font-size: 10px;
}

// .rt-thead{
//   background-color:  #FFFFFF !important;
//   color: #000 !important;
//   font-family: $font-name-Roboto-Bold;
//   font-size: 13px;
//   letter-spacing: 0px;
// color: #353844;
// text-shadow: inset 0px 3px 6px #5D5D5D29;
// text-transform: capitalize;
// opacity: 1;
// }
// .rt-td {
//   text-align: center;
//   font-size: 12px;
//   background-color:  #FFFFFF !important;
//   font-family: $font-name-Roboto-Regular;
// }
// .rt-resizable-header-content {
//   font-size: 10px;
//   font-family: $font-name-Roboto-Medium;
// }

// .ReactTable .-pagination .-btn {
//   font-size: 1em;
// }
// .ReactTable .rt-tbody {
//   overflow-x: hidden;
// }
// .downloadImgblue {
//   width: 28px;
//   height: 28px;
//   margin-left: 96%;
//   color: DodgerBlue;
// }
// .btn {
//   // background-color: DodgerBlue;
//   border: none;
//   color: DodgerBlue;
//   padding: 2px 2px;
//   cursor: pointer;
//   font-size: 20px;
//   //margin-left: 94%;
// }
// .msg {
//   text-align: right;
//   color: #57595d;
//   font-size: small;
//   margin-top: 10px;
//   margin-bottom: 1%;
//   font-weight: normal;
//   padding-right: 15px;
//   font-family: $font-name-Roboto-Regular;
// }


.first-div {
  display: flex;
  padding: 1rem;
  margin-top: 10px;
  width: 60%;
  justify-content: space-around;
}
.labels {
  display: flex;
  flex-direction: row;
}
.labels_style {
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  // margin-right: 15px;
  // margin-top: ;
  //padding: 10px;
  margin-top: -4px;
  width: 83px;
}

.labels_style-1 {
  font-family: $font-name-Roboto-Medium;
  font-size: 15px;
  // margin-right: 15px;
  // margin-top: ;
  //padding: 10px;
  margin-top: -4px;
  width: 80px;
}
.button-submit-kpi {
  padding: 5px 5px;
  background-color: #0089d3;
  border: none;
  outline: none;
  border-radius: 3px;
  color: #fff;
  font-family: $font-name-Roboto-Bold;
  cursor: pointer;
  width: 120px;
  font-weight: 500;
  letter-spacing: 1px;
}

.react-datepicker {
  // font-family: 'Red Hat Display', sans-serif !important;

  font-family: $font-name-Roboto-Bold;
  font-size: 10px !important;
  border: none !important;
  border-radius: 5px !important;
  height: 10%;
  text-align: center;
  // box-shadow: 0px 8px 16px rgba(17, 17, 17, 0.06) !important;
}
.datepicker {
  width: 100%;
}

// .react-datepicker__time-container   .react-datepicker__time
// .react-datepicker__time-box   ul.react-datepicker__time-list {
//      padding: 0;
// }

.search-trip-report {
  margin-left: 33px;
  margin-bottom: 10px;
  font-family: $font-name-Roboto-Regular;
}

.table-align {
  margin-left: -5.4% !important;
  //overflow-y: hidden;
}
