.QSAlignment {
    margin-top: 80px;
  }
  
  .topheight {
    padding-top: 10px;
    padding-left: 20px;
  }
  
  .reactLoader {
    margin-left: 50%;
    margin-top: 250px;
  }
  