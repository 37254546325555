@import "../../stylesheets/robotoFonts";

.vehicle-route-update {
    margin-top: -40px;
  
    .formwid {
      width: 100%;
      height: auto;
      overflow: auto;
      font-size: 1rem;
      font-family: $font-name-Roboto-Regular;
    }
    // .vehicle-master-id{
    //   margin-top: -22px;
    //   font-weight: 600;
    //   font-size: 1rem;
    //   font-family: $font-name-Roboto-Regular;
    //   margin-left: 15px;
    //   span{
    //     margin-right: 60px;
    //   }
    // }

    .col_5 {
      width: 48%;
      float: left;
      margin-right: 1%;
      
      margin-left: 1%;
    }
    .col_10 {
      width: 100% !important;
      float: left;
      margin-left: 2%;
      display: inline-flex !important;

    }
    // .react-datepicker {
    //   height: 10%;
    // }
    // .u {
    //   margin-top: 3%;
  
    //   width: 7%;
    //   background-color: #0089d3;
    //   color: white;
    //   height: 1.5rem;
    //   padding: 0 1.6rem;
    //   border: none;
    //   border-radius: 10px;
    //   cursor: pointer;
    // }
    // .close {
    //   color: #aaa;
    //   font-size: 14px;
    //   font-weight: bold;
    //   height: 30px;
    //   position: absolute;
    //   right: 30px;
    // }
  
    .button-route-master {
      margin-top: 200px;
      //margin-bottom: 6%;
      width: 30%;
      margin-right: 7%;
      //margin-left: 50.5%;
      background-color: #0089d3;
      color: #fff;
      height: 2.5rem;
      padding: 0 2.6rem;
      border: none;
      line-height: 1;
      text-align: center;
      font-size: 1.5rem;
      border-radius: 0.35rem;
      font-family: $font-name-Roboto-Regular;
    }

    input[type="text"],
    select,
    input[type="number"],
    input[type="date"] {
      margin: auto;
      width: 100%;
      padding: 8px 10px;
      margin: 10px 0;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-sizing: border-box;
      //display: block;
      position: relative;
      background-color: #f5f5f5;
      border-bottom: 2px solid #ccc;
      color: $color-black-primary1;
      text-align: left;
    }

    .modal-footer {
        margin-right: 35% !important;
    }

  }
  