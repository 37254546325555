@import "../../stylesheets/robotoFonts";

.dashboard-text {
  text-align: left;
  font-family: $font-name-Roboto-Bold;
  font-size: 20px;
  letter-spacing: 0px;
  margin-left: 20px;
  margin-top: 20px;
  color: #073a81;
  text-transform: capitalize;
  opacity: 1;
}

.head {
  background: #dbdee8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: 40px;
  flex: 0 0 auto;
  // @include layoutWrapperClass;
  flex: 1 1 49%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  .vehicle-status-btns {
    height: 28px;
    width: 29%;
    // padding-left: 14px;
    background-color: transparent;
    // border: 1px solid #000000;
    //border: 1px solid #dbdee8;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    // margin-top: -100px;
    margin-left: 70%;
    margin-top: -49px;

    .vehicle-status-button-png {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }

  .vehicle-status-btns:hover {
    background-color: #ed2541;
    color: white;
  }

  .vehicle-status-btns_11 {
   
    height: 27px;
    width: 28%;
    background-color: transparent;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 10px;
    font-size: 9px;
    font-weight: 700;
    font-feature-settings: normal;
    font-variant: normal;
    text-align: center;
    font-family: "Roboto-Bold";
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 70%;
    margin-top: -47px;

    .vehicle-status-button-png {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }

  .vehicle-status-btns_11:hover {
    background-color: #ed2541;
    color: white;
  }



  .vehicle-status-btns-1 {
    height: 30px;
    width: 32%;
    // padding-left: 14px;
    background-color: transparent;
    // border: 1px solid #000000;
    //border: 1px solid #dbdee8;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    // margin-top: -100px;
    margin-left: 67%;
    margin-top: -49px;

    .vehicle-status-button-png {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }

  .vehicle-status-btns-1:hover {
    background-color: #ed2541;
    color: white;
  }
  .vehicle-status-btn-one {
    height: 40px;
    width: 11%;
    background-color: transparent;
    border: none;
    background: transparent;
    cursor: pointer;
    border-radius: 10px;
    font-size: 9px;
    font-weight: 700;
    font-feature-settings: normal;
    font-variant: normal;
    text-align: center;
    font-family: "Roboto-Bold";
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-left: 88.5%;
    margin-top: -46px;
    margin-bottom: 5px;

    .vehicle-status-button-png-one {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }
  .vehicle-status-btn-one:hover {
    background-color: #ed2541;
    color: white;
  }

  .vehicle-status-btn-two {
    height: 180px;
    width: 20%;
    //padding-left: 14px;
    background-color: transparent;
    //border: 1px solid #000000;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-top: -49px;
    margin-left: 79%;
    margin-bottom: 5px;
    .vehicle-status-button-png-two {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }

  .vehicle-status-btn-two:hover {
    background-color: #ed2541;
    color: white;
  }

  .vehicle-status-btn-three {
    height: 200px;
    width: 14%;
    // padding-left: 14px;
    background-color: transparent;
    // border: 1px solid #000000;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-top: -49px;
    margin-left: 84%;
    margin-bottom: 5px;
  }

  .vehicle-status-button-png-three {
    height: 6px;
    padding-left: 2px;
    padding-bottom: 1px;
  }

  .vehicle-status-btn-three:hover {
    background-color: #ed2541;
    color: white;
  }

  .vehicle-status-btn-four {
    height: 200px;
    width: 8.5%;
    // padding-left: 14px;
    background-color: transparent;
    //border: 1px solid #000000;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-top: -51px;
    margin-left: 90.5%;
    margin-bottom: 5px;
    .vehicle-status-button-png-four {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }

  .vehicle-status-btn-four:hover {
    background-color: #ed2541;
    color: white;
  }

  .vehicle-status-btn-six {
    height: 195px;
    width: 13%;
    // padding-left: 14px;
    background-color: transparent;
    //border: 1px solid #000000;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-top: -50px;
    margin-left: 86%;
    margin-bottom: 5px;
    .vehicle-status-button-png-six {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }
  .vehicle-status-btn-six:hover {
    background-color: #ed2541;
    color: white;
    //opacity: 1;
  }

  .vehicle-status-btn-seven {
    height: 200px;
    width: 13%;
    // padding-left: 14px;
    background-color: transparent;
    //border: 1px solid #000000;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    // text-align: left;
    font-size: 9px;
    font-weight: 700;
    font-variant: normal;
    text-align: center;
    font-family: $font-name-Roboto-Bold;
    letter-spacing: 1px;
    color: black;
    text-transform: uppercase;
    opacity: 1;
    margin-top: -51px;
    margin-left: 86%;
    margin-bottom: 5px;
    .vehicle-status-button-png-seven {
      height: 6px;
      padding-left: 2px;
      padding-bottom: 1px;
    }
  }
  .vehicle-status-btn-seven:hover {
    background-color: #ed2541;
    color: white;
  }
}

.head-text {
  margin-left: 20px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}
.w-12 {
  width: 17%;
}
.w-20 {
  width: 20%;
}
.w-22 {
  width: 27%;
}

.w-30 {
  width: 30%;
}

.w-45 {
  width: 45%;
}

.w-46 {
  width: 46%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-97 {
  width: 97%;
}

.w-76 {
  width: 76%;
}

.dashboard_status_head_img-1 {
  margin-top: -52px;
  /* margin-right: 10px; */
  margin-left: 11px;
  width: 18px;
}
.dashboard_status_head_img {
  margin-top: -5px;
  margin-right: 10px;
  margin-left: -5px;
  //width: 18px;
}

.head-text-1 {
  margin-left: 40px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}
.head-text-two {
  display: flex;
  margin-left: 40px;
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  font-family: $font-name-Roboto-Bold;
  letter-spacing: 0px;
  color: #19387c;
  text-transform: uppercase;
  opacity: 1;
}

/* Parent Container */
.content_img {
  position: relative;
  width: 200px;
  //height: 200px;
  float: left;
  //margin-right: 10px;
}

/* Child Text Container */
.content_img div {
  position: absolute;
  bottom: 10;
  right: 0;
  top: 3px;
  left: 15px;
  background: rgb(60, 60, 109);
  color: white;
  //margin-bottom: 5px;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
  //opacity: 0;
  backface-visibility: hidden;
  visibility: hidden;
  -webkit-transition: visibility 0s, opacity 0.5s linear;
  transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.content_img:hover {
  cursor: pointer;
}

.content_img:hover div {
  width: 200px;
  padding: 8px 6px;
  visibility: visible;
  opacity: 17;
}

/* Parent Container */
.content_imgs {
  position: relative;
  width: 200px;
 // height: 200px;
  float: left;
  //margin-right: 10px;
}

/* Child Text Container */
.content_imgs div {
  position: absolute;
  bottom: 10;
  right: 0;
  top: -50px;
  left: 30px;
  background: rgb(60, 60, 109);
  color: white;
  //margin-bottom: 5px;
  font-size: 12px;
  font-family: $font-name-Roboto-Regular;
  opacity: 0;
  backface-visibility: hidden;
  visibility: hidden;
  //-webkit-transition: visibility 0s, opacity 0.5s linear;
  //transition: visibility 0s, opacity 0.5s linear;
}

/* Hover on Parent Container */
.content_imgs:hover {
  cursor: pointer;
}

.content_imgs:hover div {
  width: 330px;
  padding: 8px 8px;
  visibility: visible;
  opacity: 20;
}

.colorDotone {
  margin-top: 4px;
  //margin-left: 15px;
  margin-right: 7px;
  animation: pulseDot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
  background-color: var(--pulsating-dot, #f5c06b);
  border-radius: 50%;
  box-sizing: border-box;
  height: 10px;
  width: 10px;
}

.colorDotone:before {
  animation: pulseRing 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  background-color: var(--pulsating-dot, #f5c06b);
  border-radius: 45px;
  content: "";
  display: block;
  height: 300%;
  left: -100%;
  position: relative;
  top: -100%;
  width: 300%;
}

@keyframes pulseRing {
  0% {
    transform: scale(0.5);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulseDot {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}
