@import "../../stylesheets/robotoFonts";



.fleet-availability {
  display: flex;
  margin-left: 40px;
  margin-top: 10px;
}
.fleet-availability-texts {
  letter-spacing: 0.24px;
  color: #6e6e6e;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  margin-top: 10px;
  margin-left: -20px;
}

.fleet-availability-number {
  letter-spacing: 0px;
  color: #353639;
  opacity: 1;
  font-size: 15px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -15px;
  margin-left: 19px;
  // gap: 5px;
}

.perpendicular-line {
  width: 20%;
  height: 100%;
  //margin: auto;
  margin-left: 5px;
  margin-top: 60px;
  //margin-top: 30%;
  margin-bottom: 0;
  transform: rotate(90deg);
  border-color: #dbdee8;
}
.fleet-availability-img {
  margin-left: 20px;
  width: 30px;
  height: 30px;
}

.fleet-availability-imgs {
  margin-left: 27px;
  width: 25px;
  height: 25px;
  margin-top: 5px;
}

.fleet-availability-texts-1 {
  letter-spacing: 0.24px;
  color: #6e6e6e;
  text-transform: uppercase;
  opacity: 1;
  font-size: 12px;
  font-family: $font-name-Roboto-Bold;
  margin-top: 10px;
  margin-left: 2px;
}

.fleet-availability-number-1 {
  letter-spacing: 0px;
  color: #353639;
  opacity: 1;
  font-size: 15px;
  font-family: $font-name-Roboto-Bold;
  margin-top: -15px;
  margin-left: -3px;
  // gap: 5px;
}
